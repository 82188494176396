/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { RotatingBanner } from '../../components/RotatingBanner';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../Services';
import { Loading } from '../../components/Loading';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { NavLink } from 'react-router-dom';
import * as S from './style';

export const Magazines = () => {
  const [screenWidth, setScreenWidth] = useState();
  const [data, setData] = useState(null);

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/revistacopasul/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.titulo?.iv);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 699) {
        return setScreenWidth(1);
      } else if (window.innerWidth >= 699 && window.innerWidth <= 949) {
        return setScreenWidth(2);
      } else if (window.innerWidth >= 949 && window.innerWidth <= 1366) {
        return setScreenWidth(3);
      } else if (window.innerWidth > 1366) {
        return setScreenWidth(4);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ position: 'absolute', zIndex: '1', color: '#fff' }}
        />
        <S.Container>
          <S.ContentBox src={`${baseUrlImg}${data?.banner?.iv?.imagemBackground[0]}`}>
            <img
              src={`${baseUrlImg}${data?.banner?.iv?.imagemSecundaria[0]}`}
              alt="Revista de Destaque"
            />
            <S.Content>
              <h3>{data?.banner?.iv?.titulo}</h3>
              {<ReactMarkdown children={data?.banner?.iv?.conteudoMarkdown} />}
              <NavLink
                to={data?.banner?.iv?.botaoLink?.linkExterno}
                target="_blank"
              >
                {data?.banner?.iv?.botaoLink?.tituloLink}
              </NavLink>
            </S.Content>
          </S.ContentBox>
          <S.PrevEditionsBox>
            <h2>{data?.tituloLista?.iv.toUpperCase()}</h2>
            <S.CardsBox>
              <RotatingBanner slidesToShow={screenWidth} slidesToScroll={1}>
                {data &&
                  data?.banners?.iv.map((item) => (
                    <S.Cards key={item?.banner?.schemaId}>
                      <S.ContentCard
                        onClick={() => {
                          window.open(
                            `${item?.banner?.botaoLink?.linkExterno}`,
                            '_blank',
                          );
                        }}
                      >
                        <img
                          src={`${baseUrlImg}${item?.banner?.imagemBackground}`}
                          alt={item.title}
                        />
                        <h4>{item?.banner?.titulo}</h4>
                      </S.ContentCard>
                    </S.Cards>
                  ))}
              </RotatingBanner>
            </S.CardsBox>
          </S.PrevEditionsBox>
        </S.Container>
      </div>
    );
  }
};
