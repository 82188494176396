import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  @media only screen and (min-width: 800px) {
    height: 445px;
  }
`;

export const BannerBox = styled.div`
  color: var(--white-color);
  width: 115%;
  margin: auto 0;
  margin-right: 1rem;
  max-width: 15.75rem;

  @media only screen and (min-width: 600px) {
    margin-left: -22rem;
  }
  @media only screen and (min-width: 800px) {
    z-index: 2;
  }
  p {
    margin: 0.5rem 0 1rem;
    font-size: 1rem;
  }
  h3 {
    font-size: 1.5rem;
  }
`;

export const ButtonBackground = styled.button`
  gap: 0.5rem;
  display: flex;
  justify-content: center;
  background-color: var(--seventh-color);
  border-color: var(--seventh-color);
  color: var(--second-color);
  padding: 0.625rem 2rem;
  width: 14rem;
  margin-left: -4rem;

  border-radius: 0.5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media only screen and (min-width: 600px) {
    margin-left: 0;
  }
  svg {
    font-size: 1rem;
  }
`;

export const ImgBox = styled.div`
  margin-left: -3rem;
  @media only screen and (min-width: 800px) {
    margin-left: -5rem;
  }
  img {
    max-width: 47rem;
    width: 200%;
    @media only screen and (min-width: 600px) {
    }
  }
`;
