import styled from 'styled-components';

export const cardsBox = styled.div`
  position: relative;
  z-index: 5;
  padding: 0 2rem 3rem;
  background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
  margin-top: ${(props) => props.marginTop};
  h3 {
    text-align: center;
    color: var(--white-color);
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
`;

export const CardLinksBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.25rem;
`;

export const CardLinks = styled.div`
  padding: 1rem;
  width: 21.75rem;
  height: 8.875rem;
  border-radius: 0.625rem;
  background: #ffffff;
  box-shadow: 10px 12px 14px rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  h5 {
    font-size: 1rem;
    color: var(--second-color);
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  svg {
    color: var(--seventh-color);
    font-size: 1rem;
    margin-right: 0.313rem;
  }
  p {
    font-size: 0.813rem;
  }
  a {
    cursor: pointer;
  }
`;
