import React, { useEffect, useState } from 'react';
import { RotatingBanner } from '../../components/RotatingBanner';
import { format, parseISO } from 'date-fns';
import { useStartupState } from '../../Providers/StartupState';
import { handleGetData } from '../../Services';
import * as S from './style';
import { NavLink } from 'react-router-dom';

export const LatestNews = ({ slides, BGColor }) => {
  const [screenWidth, setScreenWidth] = React.useState();
  const [noticias, setNoticias] = useState([]);

  const { token } = useStartupState();

  useEffect(() => {
    if (token) {
      handleGetData(
        token,
        `api/content/copasul/noticia/?$top=${3}&?$search=noticia`,
      )
        .then((response) => {
          setNoticias(response?.data?.items);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setNoticias([]);
          console.log(e.message);
        });
    }
  }, [token]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 799) {
        return setScreenWidth(1);
      } else if (window.innerWidth >= 799 && window.innerWidth <= 1099) {
        return setScreenWidth(2);
      } else if (window.innerWidth > 1099) {
        return setScreenWidth(3);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <S.SlidesBox BGColor={BGColor}>
      <S.RotatingBannerBox>
        <p className="title">Últimas Notícias relacionadas - copasul</p>
        <RotatingBanner slidesToShow={screenWidth} slidesToScroll={screenWidth}>
          {noticias &&
            noticias.map((item) => (
              <div key={item?.schemaId} style={{ padding: '0 1rem' }}>
                <S.SubRotatingBanner>
                  <S.RotatingBannercontent>
                    <span>
                      {format(
                        parseISO(item?.data?.dataNoticia?.iv),
                        "dd, MMM 'de' yyyy",
                      )}
                    </span>
                    <p>{item?.data?.subTitulo?.iv}</p>
                    <NavLink to={`/paginaNoticia/${item?.data?.idUrl?.iv}`}>
                      Leia mais...
                    </NavLink>
                  </S.RotatingBannercontent>
                </S.SubRotatingBanner>
              </div>
            ))}
        </RotatingBanner>
      </S.RotatingBannerBox>
    </S.SlidesBox>
  );
};
