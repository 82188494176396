import React from 'react';
import BGImage from '../../assets/background_notfound.jpg';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  flex-direction: column;
  img {
    width: 170%;
    @media only screen and (min-width: 800px) {
      width: 100%;
    }
  }
  div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -5rem;
    height: 30%;
    width: 100%;

    a {
      color: #fff;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.1rem;
      padding: 0 1rem;
      @media only screen and (min-width: 600px) {
        padding: 0 3rem;
      }
      @media only screen and (min-width: 800px) {
        padding: 0 5rem;
      }
    }
  }
`;

export const NotFound = () => {
  return (
    <Container>
      <img src={BGImage} alt="" />
      <div>
        <NavLink to="/">
          <ArrowBackIosIcon /> Voltar
        </NavLink>
      </div>
    </Container>
  );
};
