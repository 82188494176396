import styled from 'styled-components';

export const Container = styled.div`
  color: var(--white-color);
  font-family: 'prompt';
  font-size: 1.5rem;
  justify-content: center;
  display: flex;
  padding: 1.5rem 0;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  a {
    margin: 0 0.5rem;
    color: var(--white-color);
    ::after {
      content: '';
      display: block;
      height: 0.3rem;
      background-color: transparent;
      border: 1px solid var(--white-color);
      border-radius: 0.5rem;
      margin-top: 0.3rem;
    }
  }
  .active {
    ::after {
      background-color: var(--sixth-color);
      border: none;
    }
  }
`;
