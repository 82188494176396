import styled from 'styled-components';

export const Container = styled.div`
  color: var(--white-color);
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5rem;
`;
export const ListBox = styled.div`
  padding: 0 2.5rem;
  margin: 0 auto 0.5rem;
  font-size: 1rem;
  max-width: 68.25rem;
  width: 80%;
  .ReactMarkdown {
    p {
      margin-bottom: 1rem;
    }
  }
  @media only screen and (min-width: 900px) {
    padding: 0;
  }
`;

export const QuotationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  margin: 3rem 0;
  @media only screen and (min-width: 1000px) {
    align-items: center;
    flex-direction: row;
    align-items: stretch;
  }
`;

export const ImgBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.45);
  margin: 0 auto;
  width: 80%;

  @media only screen and (min-width: 1000px) {
    width: 65%;
    margin: 0;
  }
  img {
    width: 100%;
  }
`;

export const TimeLineBox = styled.div`
  width: 100%;
  background-color: var(--second-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--white-color);
  padding: 3rem 0;

  h2 {
    text-align: center;
    width: 60%;
    ::after {
      content: '';
      display: block;
      height: 0.3rem;
      background-color: var(--seventh-color);
      border-radius: 0.5rem;
      margin-top: 0.3rem;
      width: 5rem;
      margin: 0 auto;
    }
  }
  p {
    text-align: center;
    font-size: 1rem;
    max-width: 37.188rem;
    width: 80%;
  }
`;
export const TapiocaBox = styled.div`
  background-image: url(${(props) => props.src});
  background-position: 80%;
  background-repeat: no-repeat;
  background-size: cover;

  font-size: 1rem;
  color: var(--white-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2.188rem;
  padding: 2rem 2rem -1px;

  .ReactMarkdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 18.625rem;
    text-align: center;
    @media only screen and (min-width: 743px) {
      text-align: left;
      :nth-child(1) {
        order: 2;
      }
    }
  }

  img {
    min-width: 13.375rem;
    width: 50%;
    max-width: 25.313rem;
  }
`;

export const VideoBox = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 80%;
  max-width: 49.5rem;
  margin: 1.813rem auto 1rem;
  @media only screen and (min-width: 800px) {
    height: 22.375rem;
    padding-top: 0;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    @media only screen and (min-width: 800px) {
      position: relative;
    }
  }
`;
