import styled from 'styled-components';

export const SlidesBox = styled.div`
  background-color: ${({ BGColor }) => BGColor && BGColor};
  /* width: 22rem; */
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3rem 0;
  margin-top: -50px;
  @media only screen and (min-width: 800px) {
    /* width: 44rem; */
  }
  @media only screen and (min-width: 1000px) {
    margin: 0 auto;
    background: transparent;
  }
  @media only screen and (min-width: 1100px) {
    /* width: 66rem; */
  }
`;

export const RotatingBannerBox = styled.div`
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 800px) {
    width: 700px;
  }
  @media only screen and (min-width: 1100px) {
    width: 1025px;
  }

  .slick-next {
    margin-right: -10px;
  }
  .slick-prev {
    margin-left: -10px;
  }
  .slick-prev,
  .slick-next {
    path {
      display: none;
      fill: var(--white-color);
      @media only screen and (min-width: 1366px) {
        display: block;
      }
    }
  }

  .title {
    text-align: center;
    font-size: 0.875rem;
    font-weight: bold;
    margin: 0 auto 0.875rem;
    width: 100%;
  }
`;

export const SubRotatingBanner = styled.div`
  padding: 0 0.625rem;
  display: flex !important;
  justify-content: center;
  margin: 0 auto;
`;

export const RotatingBannercontent = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.45);
  width: 80%;
  span {
    font-size: 0.875rem;
  }
  p {
    font-weight: bold;
    margin: 0.5rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.125rem;
  }
  a {
    color: var(--white-color);
    font-size: 0.875rem;
  }
`;
