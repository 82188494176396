import styled from "styled-components";

export const WrapShareButtons = styled.div`
  display: flex;
  position: absolute;
  padding-left: 10px;
  border-radius: 5px;
  box-shadow: 1px 2px 8px 5px rgba(0, 0, 0, 0.09);
  margin-left: 3%;
  margin-top: -60px;
  background-color: var(--white-color);
  & > .SocialMediaShareButton {
    display: inline-block;
    margin: 10px 0;
    margin-right: 10px;
  }

  & > .SocialMediaShareButton > .btn {
    width: 64px;
    height: 32px;
    padding: 0;
    border-radius: 0;
    border: 0;
  }

  svg {
    font-size: 1.8rem !important;
    color: var(--white-color) !important;
  }
  button {
    padding: 2px;
    border-radius: 5px;
  }

  .facebook {
    background-color: #3b568d;
  }

  .linkedin {
    background-color: #0e76a8;
  }

  .telegram {
    background-color: #229ed9;
  }

  .whatsApp {
    background-color: #34af23;
  }

  .twitter {
    background-color: #00ade8;
  }
  .email {
    background: #ff0000;
  }
  .instagram {
    background: #f09433;
    background: -moz-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: -webkit-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }
`;
