import styled from 'styled-components';

export const Container = styled.div`
  color: var(--white-color);
  background-image: url(${(props) => props.src});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 6.875rem;
  padding-bottom: 4.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    width: 85%;
    max-width: 41.625rem;
    text-align: center;
    font-size: 1rem;
    font-family: 'Prompt';
    margin-bottom: 2.5rem;
    text-transform: uppercase;
    @media only screen and (min-width: 800px) {
      font-size: 1.5rem;
    }
  }
  p {
    width: 90%;
    max-width: 44.688rem;
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
`;

export const Logo = styled.img`
  width: 50%;
  max-width: 17.5rem;
  margin-bottom: 2rem;
`;

export const Comment = styled.div`
  border: 1px solid var(--white-color);
  border-radius: 0.625rem;
  box-sizing: border-box;
  padding: 2rem;
  width: 90%;
  max-width: 45.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    width: 10.188rem;
    border-radius: 50%;
  }
  p {
    font-size: 1rem;
    text-align: center;
    width: 100%;
    max-width: 29.5rem;
    gap: 0.625rem;
    display: flex;
    flex-direction: column;
    span {
      width: 80%;
      max-width: 24.25rem;
    }
  }
`;

export const SliderBox = styled.div`
  width: 90%;
  max-width: 60rem;
  text-align: center;
  margin: 2.875rem auto;
  .slick-prev,
  .slick-next {
    path {
      display: none;
      @media only screen and (min-width: 1100px) {
        display: block;
      }
    }
  }
`;

export const VideoBox = styled.div`
  background: linear-gradient(180deg, #4ed209 0%, #13a640 52.6%, #066831);
  color: var(--white-color);
  max-width: 57.25rem;
  border-radius: 0.625rem;
  padding: 1rem;
  margin: 0 auto;
  p {
    text-align: center;
    padding: 0.5rem 0 1rem;
    margin: 0 auto;
    width: 100%;
  }
`;

export const ReactPlayerBox = styled.div`
  height: 40vw;
  max-height: 37.563rem;
  margin: 0 auto;
`;
