/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ReactComponent as LocationIcon } from '../../assets/location_icon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/phone_icon.svg';
import { ReactComponent as EmailIcon } from '../../assets/email_icon.svg';
import { ReactComponent as PersonIcon } from '../../assets/person_icon.svg';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../Services';
import { Loading } from '../../components/Loading';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import * as S from './style';

export const OurUnits = () => {
  const [data, setData] = useState(null);
  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/unidades/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.titulo?.iv);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ position: 'absolute' }}
        />
        <S.Container bgOurUnits={data?.imagemBackground?.iv[0]}>
          {data?.UnidadesArray?.iv &&
            data?.UnidadesArray?.iv.map((item, index) => (
              <S.Card key={index}>
                <img
                  src={`${baseUrlImg}${item?.unidade?.ImgUnidade[0]}`}
                  alt="imagem da unidade"
                />
                <S.ContentCard>
                  <h3>{item?.unidade?.NomeUnidade}</h3>
                  {item?.unidade?.DadosUnidade[0]?.Texto && (
                    <S.Paragraph>
                      <div className="iconBox">
                        <LocationIcon />
                      </div>
                      <p>{item?.unidade?.DadosUnidade[0]?.Texto}</p>
                    </S.Paragraph>
                  )}
                  {item?.unidade?.DadosUnidade[1]?.Texto && (
                    <S.Paragraph>
                      <div className="iconBox">
                        <PhoneIcon />
                      </div>
                      <p>
                        {item?.unidade?.DadosUnidade[1]?.Texto.replace(
                          /^(\d{2})\s*(\d{4,5})-?(\d{4})$/,
                          '($1) $2-$3',
                        )}
                      </p>
                    </S.Paragraph>
                  )}
                  {item?.unidade?.DadosUnidade[2]?.Texto && (
                    <S.Paragraph>
                      <div className="iconBox">
                        <EmailIcon />
                      </div>
                      <p>{item?.unidade?.DadosUnidade[2]?.Texto}</p>
                    </S.Paragraph>
                  )}
                  {item?.unidade?.DadosUnidade[3]?.Texto && (
                    <S.Paragraph>
                      <div className="iconBox">
                        <PersonIcon />
                      </div>
                      <p >
                        {item?.unidade?.DadosUnidade[3]?.Texto}{' '}
                        <span>{item?.unidade?.DadosUnidade[4]?.Texto}</span>
                      </p>
                    </S.Paragraph>
                  )}
                </S.ContentCard>
              </S.Card>
            ))}
        </S.Container>
      </>
    );
  }
};
