import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  color: var(--white-color);
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 6.875rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 6.75rem;

  @media only screen and (min-width: 900px) {
    padding: 6.875rem 3rem;
  }
`;

export const LeftFormBox = styled.div`
  width: 90%;
  max-width: 33.125rem;
`;

export const HeaderFormBox = styled.header`
  color: var(--black-color);
  margin-bottom: 2.688rem;
  color: var(--white-color);
  h2 {
    align-items: center;
    font-size: 1.25rem;
    display: flex;
    gap: 1rem;
    line-height: normal;
    margin-bottom: 2.688rem;
    @media only screen and (min-width: 800px) {
      font-size: 2.25rem;
    }
  }
  span {
    font-size: 0.875rem;
  }
  svg {
    color: var(--second-color);
    font-size: 2.8125rem;
    display: block;
    width: 9.5rem;
  }
`;

export const Form = styled.form`
  position: relative;
  input,
  textarea,
  select {
    background-color: var(--white-color);
    border-radius: 10px;
    width: 100%;
    height: 2.68rem;
    border: none;
    padding-left: 1rem;
    box-sizing: border-box;
  }
  .boxInput {
    margin-bottom: 1.5rem;
  }

  .textarea {
    margin-bottom: 2rem;
    textarea {
      height: 11.8rem;
      resize: none;
      padding: 1rem;
    }
  }

  .error {
    margin-bottom: 0%.5rem;
    color: #f00;
    position: absolute;
  }
  button {
    background-color: var(--second-color);
    color: var(--white-color);
    font-size: 0.875rem;
    padding: 0.56rem 4.063rem;
    border-radius: 10px;
    margin: 0 auto;
    display: block;
    @media only screen and (min-width: 1039px) {
      display: inline-block;
    }
  }
`;

export const InfoBox = styled.div`
  border: 1px solid var(--second-color);
  padding: 1.56rem;
  color: var(--white-color);
  border-radius: 10px;
  margin-top: 5rem;
  font-size: 0.813rem;
  width: 90%;
  max-width: 16rem;
  p {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    svg {
      margin-right: 0.313rem;
    }
  }
`;

export const NavLinkCustom = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 247px;
  height: 43px;
  margin-bottom: 1.125rem;
  background: var(--white-color);
  box-shadow: 10px 12px 14px rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  color: var(--second-color);
  svg {
    color: var(--seventh-color);
  }
`;

export const MapsBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
