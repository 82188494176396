/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { ContactUs } from '../../components/ContactUs';
import { LatestNews } from '../../components/LatestNews';
import { LinkServices } from '../../components/LinkServices';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../Services';
import { Loading } from '../../components/Loading';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import * as S from './style';

export const Starch = () => {
  const [data, setData] = useState(null);
  const [priceImg, setPriceImg] = React.useState();
  const [BgImg, setBgImg] = React.useState();

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/fecularia/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.seo?.iv?.TituloSEO);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setBgImg(data?.imagemFundoMobile?.iv[0]);
        return setPriceImg(data?.imagemCotacao?.iv[0]);
      } else if (window.innerWidth >= 800 && window.innerWidth <= 1100) {
        setBgImg(data?.imagemFundoWeb?.iv[0]);
        return setPriceImg(data?.imagemCotacao?.iv[1]);
      } else if (window.innerWidth > 1100) {
        setBgImg(data?.imagemFundoWeb?.iv[0]);
        setPriceImg(data?.imagemCotacao?.iv[1]);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ color: '#fff', position: 'absolute' }}
        />
        <S.Container src={`${baseUrlImg}${BgImg}`}>
          <section>
            <S.ListBox>
              <ReactMarkdown
                className="ReactMarkdown"
                children={data?.conteudoMarkdown?.iv}
              />
            </S.ListBox>
            <S.QuotationBox>
              <S.ImgBox>
                <img src={`${baseUrlImg}${priceImg}`} alt="" style={{ width: '100%' }} />
              </S.ImgBox>
              <LinkServices
                linksServices={data?.links?.iv}
                BGColor={'#3d362f'}
              />
            </S.QuotationBox>
          </section>
          <LatestNews BGColor={'#3d362f'} />
        </S.Container>
        <S.TapiocaBox src={`${baseUrlImg}${data?.bannerPromocional?.iv?.imagemBackground[0]}`}>
          <ReactMarkdown
            className="ReactMarkdown"
            children={data?.bannerPromocional?.iv?.conteudoMarkdown}
          />
          <img src={`${baseUrlImg}${data?.bannerPromocional?.iv?.imagemSecundaria[0]}`} alt="" />
        </S.TapiocaBox>

       {data?.bannerReceita && <S.TimeLineBox >
          <h2>{data?.bannerReceita?.iv?.titulo}</h2>
          <S.VideoBox>
            <ReactPlayer
              className="react-player"
              url={data?.bannerReceita?.iv?.botaoLink?.linkExterno}
              width="100%"
              height="100%"
              controls={false}
              playing={false}
            />
          </S.VideoBox>
          <p>{data?.bannerReceita?.iv?.subtitulo}</p>
        </S.TimeLineBox>}
        <ContactUs />
      </div>
    );
  }
};
