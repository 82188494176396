/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { ContactUs } from '../../components/ContactUs';
import { OtherServices } from '../../components/OtherServices';
import { baseUrlImg, handleGetData } from '../../Services';
import { useStartupState } from '../../Providers/StartupState';
import { Loading } from '../../components/Loading';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import * as S from './style';

export const AgriculturalInputs = () => {
  const [data, setData] = useState(null);
  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/insumosagricolas/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.seo?.iv?.TituloSEO);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ color: '#fff', position: 'absolute' }}
        />
        <S.Container src={`${baseUrlImg}${data?.imagemBackground?.iv[0]}`}>
          <S.BannerInputsBox>
            <S.Text>
              <ReactMarkdown
                className="ReactMarkdown"
                children={data?.conteudoMarkdown?.iv}
              />
            </S.Text>

            <S.CardsInputsBox>
              {data?.cartoes?.iv &&
                data?.cartoes?.iv.map((item) => (
                  <S.Cards key={item?.cartao?.schemaId}>
                    <img src={`${baseUrlImg}${item?.cartao?.imagem[0]}`} alt="imagem do cartão" />
                    <h5>{item?.cartao?.titulo}</h5>
                    <p>{item?.cartao?.subTitulo}</p>
                  </S.Cards>
                ))}
            </S.CardsInputsBox>
          </S.BannerInputsBox>
        </S.Container>
        <OtherServices
          servicesLinks={data?.cartoesServicos?.iv}
          marginTop={'-5rem'}
        />
        <ContactUs />
      </div>
    );
  }
};
