/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { handleGetData } from '../../Services';
import { useStartupState } from '../StartupState';

const HeaderStatesContext = createContext();

export const HeaderStates = ({ children }) => {
  const [title, setTitle] = useState('');
  const [bgHeader, setBgHeader] = useState(null);
  const [logoHeader, setLogoHeader] = useState(null);

  const [menu, setMenu] = useState([]);

  const { token, setError, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/layoutmenu/')
        .then((response) => {
          setMenu(response?.data?.items[0]?.data?.listaMenus?.iv);
        })
        .catch((e) => {
          setMenu([]);
          console.log('Error =>', e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  return (
    <HeaderStatesContext.Provider
      value={{
        title,
        setTitle,
        bgHeader,
        setBgHeader,
        logoHeader,
        setLogoHeader,
        menu,
        setMenu,
      }}
    >
      {children}
    </HeaderStatesContext.Provider>
  );
};

export const useHeaderStates = () => useContext(HeaderStatesContext);
