import styled from "styled-components";

export const ButtonCustomTransparent = styled.button`
  border-radius: 10px;
  color: var(--first-color);
  border: 1px solid var(--second-color);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
`;

export const ButtonCustomBackground = styled(ButtonCustomTransparent)`
  background-color:var(--second-color);
  border: 1px solid var(--second-color);
  color:var(--white-color);
`;
