import styled, { css } from 'styled-components';

export const Container = styled.div`
  mix-blend-mode: multiply;
  background-color: var(--second-color);
`;

export const ContentBox = styled.div`
  ${({ src }) => css`
    background: url(${src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    padding: 5.625rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    gap: 1rem;
    margin-bottom: 3rem;
    font-size: 1rem;
    color: var(--white-color);
  `}

  img {
    width: 16rem;
  }
`;

export const Content = styled.div`
  width: 90%;
  max-width: 18.625rem;
  h3 {
    width: 60%;
    margin-bottom: 1.5rem;
  }
  p {
    :nth-child(2) {
      margin-bottom: 1rem;
    }
    :nth-child(3) {
      margin-bottom: 1.5rem;
    }
  }
`;

export const PrevEditionsBox = styled.div`
  padding-bottom: 4.4rem;
  h2 {
    padding: 2.813rem 0;
    text-align: center;
    color: var(--white-color);
    ::after {
      content: '';
      display: block;
      height: 0.3rem;
      background-color: var(--seventh-color);
      border-radius: 0.5rem;
      margin-top: 0.3rem;
      width: 5rem;
      margin: 0 auto;
    }
  }
`;

export const CardsBox = styled.div`
  width: 300px;
  margin: 0 auto;

  @media only screen and (min-width: 700px) {
    width: 600px;
  }
  @media only screen and (min-width: 950px) {
    width: 900px;
  }
  @media only screen and (min-width: 1200px) {
    width: 1200px;
  }
  .slick-prev,
  .slick-next {
    path {
      display: none;
      @media only screen and (min-width: 600px) {
        display: block;
      }
    }
  }
  .slick-next {
    margin-right: 1.25rem;
  }
  .slick-prev {
    margin-left: 1.25rem;
    z-index: 1;
  }
  .slick-initialized .slick-slide {
    text-align: center;
  }
`;

export const Cards = styled.div`
  box-sizing: border-box;
  width: fit-content !important;

  h4 {
    font-size: 1rem;
    color: var(--white-color);
    margin-top: 1.25rem;
  }
`;

export const ContentCard = styled.button`
  margin: 0 auto;
  width: fit-content;
  padding: 0 13px;
`;
