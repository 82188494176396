/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link } from 'react-router-dom';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../Services';
import * as S from './style';

export const Footer = () => {
  const { menu } = useHeaderStates();
  const [data, setData] = useState(null);

  const { token, setError, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/layoutfooter/')
        .then((response) => {
          setData(response?.data?.items[0]?.data?.footer?.iv);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  return (
    <S.FooterBox>
      <S.TopPage href="#">
        <KeyboardArrowUpIcon />
      </S.TopPage>
      <S.FooterContetBox>
        <S.ColumnLinkBox>
          <S.ColumnBox>
            <img src={`${baseUrlImg}${data?.logo[0]}`} alt="logo copasul" tabIndex="0" />
            <S.Description tabIndex="0">{data?.texto}</S.Description>
            <p tabIndex="0">
              <LocalPhoneIcon />
              {data?.informacoes[0]?.telefone
                .toString()
                .replace(/^(\d{2})\s*(\d{4,5})-?(\d{4})$/, '($1) $2-$3')}
            </p>
            <p tabIndex="0">
              <EmailIcon />
              {data?.informacoes[0]?.email}
            </p>
            <p tabIndex="0">
              <LocationOnIcon />
              {data?.informacoes[0]?.endereco}
            </p>
          </S.ColumnBox>
          {menu &&
            menu.map((item, index) => (
              <Fragment key={index}>
                {item?.menu?.rota !== null ? (
                  <S.TitlesColumn tabIndex="0" to={`${item?.menu?.rota}`}>
                    &bull; {item?.menu?.tituloMenu}
                  </S.TitlesColumn>
                ) : (
                  <S.TitlesColumn>
                    &bull; {item?.menu?.tituloMenu}
                    {item?.menu?.subMenu?.length > 0 &&
                      item?.menu?.subMenu?.map((subItem, index) => (
                        <Link
                          tabIndex="0"
                          key={index}
                          to={`${subItem?.rotaSubmenu}`}
                        >
                          {subItem?.tituloSubmenu}
                        </Link>
                      ))}
                  </S.TitlesColumn>
                )}
              </Fragment>
            ))}
        </S.ColumnLinkBox>
      </S.FooterContetBox>
      <S.SocialNetworksBox>
        <p tabIndex="0">Siga nossas redes:</p>
        <S.SocialNetworks>
          {data &&
            data?.redesSociais?.map((item) => (
              <button
                tabIndex="0"
                title={item?.linkRedeSocial?.tituloLink}
                onClick={() => {
                  window.open(`${item?.linkRedeSocial?.linkExterno}`, '_blank');
                }}
              >
                <img
                  src={`${baseUrlImg}${item?.linkRedeSocial?.imagemRelacionada[0]}`}
                  alt="icone"
                />
              </button>
            ))}
        </S.SocialNetworks>
      </S.SocialNetworksBox>
      <S.PartnersBox>
        <p tabIndex="0">{data && data.parceirosFraseInicial}</p>
        <S.Partners>
          {data &&
            data?.parceiros?.map((item) => (
              <button
                tabIndex="0"
                title={item?.linkParceiro?.tituloLink}
                onClick={() => {
                  window.open(`${item?.linkParceiro?.linkExterno}`, '_blank');
                }}
              >
                <img
                  src={`${baseUrlImg}${item?.linkParceiro?.imagemRelacionada[0]}`}
                  alt="icone"
                />
              </button>
            ))}
        </S.Partners>
      </S.PartnersBox>
      <S.LogoFooterBox>
        <img src={`${baseUrlImg}${data?.logoGrande[0]}`} alt="logo no rodapé" tabIndex="0" />
      </S.LogoFooterBox>
      <S.copyright tabIndex="0">{data?.copyright}</S.copyright>
    </S.FooterBox>
  );
};
