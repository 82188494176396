import styled from "styled-components";

export const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.313rem 0.688rem 0.938rem;
  border-radius: 0.625rem;
  z-index: 1;
  width: 12.75rem;
  height: 15.063rem;
  box-sizing: border-box;
`;
