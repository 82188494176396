import styled from 'styled-components';

export const Container = styled.div`
  color: var(--white-color);
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const ListBox = styled.div`
  padding: 0 2.5rem;
  margin: 0 auto 0.5rem;
  font-size: 1rem;

  p{
    :nth-child(1){
      margin-bottom: 1rem;
    }
  }
  
  @media only screen and (min-width: 800px) {
    width: fit-content;
  }
  @media only screen and (min-width: 900px) {
    padding: 0;
  }
`;

export const QuotationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  margin: 3rem 0;
  @media only screen and (min-width: 1000px) {
    align-items: center;
    flex-direction: row;
    align-items: stretch;
  }
`;

export const ImgBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.45);
  margin: 0 auto;
  width: 80%;

  @media only screen and (min-width: 1000px) {
    width: 65%;
    margin: 0;
  }
  img {
    width: 100%;
  }
`;