import styled from 'styled-components';

export const LinkServicesBox = styled.div`
  width: 100%;
  background-color: ${({ BGColor }) => BGColor && BGColor};
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 1.5rem;
  @media only screen and (min-width: 1000px) {
    background: transparent;
    width: fit-content;
    padding: 0;
  }
`;

export const LinkServices = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.45);
  svg {
    font-size: 1rem;
    color: var(--seventh-color);
  }
  a {
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: underline;
    margin: 0.5rem 0;
    :visited {
      color: var(--white-color);
    }
  }
  h3 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

