import axios from 'axios';
import { Api } from '../Api';

export const baseUrlImg = "https://cloud.squidex.io/api/assets/copasul/"

export const handleGetToken = async () => {
  const response = await Api.post(
    'identity-server/connect/token',
    {
      grant_type: 'client_credentials',
      client_id: 'copasul:website',
      client_secret: 'rwx90m9xvtpeusyq9ynji7yuych15mu8xutfopxizvcx',
      scope: 'squidex-api',
    },
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    },
  ).catch((error) => console.log('error', error));
  return response;
};

export const handleGetData = async (token, link) => {
  const response = await Api.get(link, {
    headers: {
      'X-Resolve-Urls': '*',
      Authorization: `Bearer ${token.toString()}`,
    },
  });
  return response;
};

export const getVidesChannel = async (results, videoTitle) => {
  const baseURL = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCRNeyileM2kEiDwcS1jzFlQ&maxResults=${results}&order=date&type=video&q=${videoTitle}&key=AIzaSyCkC1q2O-9_CoqzOZcDn4QeQlgcb0GqEAY`;

  const response = await axios.get(baseURL);
  return response.data;
};

export const handlePostContact = async (link, payload, token) => {
  const response = await Api.post(link, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.toString()}`,
    },
  });
  return response;
};
