import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ bgOurUnits }) => css`
    background: url(${bgOurUnits});
    position: relative;
    padding: 5.625rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: var(--black-color);
  `}
`;

export const ContentBox = styled.div`
  background-color: var(--white-color);
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  line-height: 2rem;
  box-shadow: 1px 2px 8px 5px rgba(0, 0, 0, 0.09);
  border-radius: 10px;

  @media only screen and (min-width: 1366px) {
    padding: 3.125rem 4.625rem;
  }

  h3 {
    width: 90%;
    max-width: 29.625rem;
    text-align: center;
    margin-bottom: 3.75rem;
    font-size: 1.5rem;
  }
  img {
    width: 90%;
    max-width: 33.125rem;
    border-radius: 0.625rem;
    margin-bottom: 2.5rem;
  }
  p {
    width: 90%;
    font-size: 0.875rem;
  }
`;

export const CardsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2.5rem;
  gap: 1.5rem;

  img {
    width: 90%;
    margin: 0 auto 1.375rem;
    cursor: pointer;
    @media only screen and (min-width: 740px) {
      max-width: 17.75rem;
    }
  }
`;

export const HeaderPage = styled.header`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--fourth-color);
  color: var(--fourth-color);
  width: 100%;
  font-size: 0.813rem;
  margin-bottom: 1rem;
`;

export const FooterPage = styled(HeaderPage)`
  border: none;
  margin: 0;
  border-top: 1px solid var(--fourth-color);
  margin-top: 2rem;
  padding-top: 1.25rem;
  button {
    display: flex;
    align-items: center;
    color: var(--fourth-color);
    svg {
      font-size: 1rem;
    }
  }
`;

export const SocialMediaBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ContentModal = styled.div`
  background-color: var(--white-color);
  padding: 0.625rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    border-radius: 0.625rem;
    max-height: 80vh
  }
  button {
    color: var(--white-color);
    svg {
      width: 3rem;
      height: 3rem;
    }
  }
`;
