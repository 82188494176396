import React from "react";
import * as S from "./style";

export const ButtonCustomTransparent = ({ children, ...props }) => {
  return (
    <S.ButtonCustomTransparent {...props}>{children}</S.ButtonCustomTransparent>
  );
};

export const ButtonCustomBackground = ({ children, ...props }) => {
  return (
    <S.ButtonCustomBackground {...props}>{children}</S.ButtonCustomBackground>
  );
};
