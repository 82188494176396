import styled from 'styled-components';
import { ButtonCustomTransparent } from '../../components/ButtonCustom';

export const Container = styled.div`
  background-color: #f0f2e2;
  max-width: 100%;
  position: relative;
  overflow: hidden;

  h2 {
    margin: 4.375rem auto;
    text-align: center;
    font-size: 2.25rem;
    color: var(--first-color);
  }
  .banner {
    .slick-slider {
      .slick-next {
        margin-right: 3.125rem !important;
      }
      .slick-prev {
        margin-left: 3.125rem;
      }
    }
    .slick-prev,
    .slick-next {
      path {
        fill: var(--white-color);
      }
    }
  }
  .slick-dots {
    transform: translate(0, -3.125rem) !important;
  }
  .slick-prev,
  .slick-next {
    path {
      display: none;
      fill: var(--second-color);
      @media only screen and (min-width: 50rem) {
        display: block;
      }
    }
  }
`;

export const BannerBox = styled.div`
  box-sizing: border-box;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 10%;
  padding: 3rem 2rem;
  img {
    max-width: 15rem;
    transform: rotate(20deg);
    width: 32%;
    @media only screen and (min-width: 31.25rem) {
      width: 30%;
    }
    @media only screen and (min-width: 56.25rem) {
      width: 25%;
    }
  }
`;

export const ContentBanner = styled.div`
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 50%;
  max-width: 25rem;
  font-size: 0.813rem;
  @media only screen and (min-width: 50rem) {
    width: 50%;
    font-size: 1rem;
  }
  h2 {
    font-size: 1rem;
    color: var(--white-color);
    text-align: left;
    margin: 0;
    @media only screen and (min-width: 50rem) {
      font-size: 2.5rem;
    }
  }
  span {
    margin: 0 0 0.5rem;
    @media only screen and (min-width: 50rem) {
      margin: 1.875rem 0 1.5rem;
    }
  }
`;

export const ReadMoreButton = styled(ButtonCustomTransparent)`
  color: var(--white-color);
  border-color: var(--white-color);
  max-width: 11.938rem;
  min-width: 6.25rem;
  font-size: 0.813rem;
  @media only screen and (min-width: 50rem) {
    padding: 0.625rem 3.125rem;
    font-size: 1rem;
  }
`;

export const CardBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  gap: 1.75rem;
  color: var(--white-color);
  text-align: center;
  flex-wrap: wrap;
  padding: 0 2.5rem;
  h3 {
    font-size: 1.5rem;
  }
  p {
    margin: 0.375rem 0 1.063rem;
    font-size: 0.75rem;
    line-height: 0.938rem;
  }
`;

export const CardTextBox = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardButtonBox = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const NewsBannerBox = styled.div`
  margin: 0 auto;
  max-width: 25rem;
  @media only screen and (min-width: 46.875rem) {
    max-width: 40rem;
  }
  @media only screen and (min-width: 62.5rem) {
    padding-bottom: 1rem;
  }
  @media only screen and (min-width: 78.125rem) {
    max-width: 67.75rem;
  }
`;

export const NewsBanner = styled.div`
  padding: 0 0.594rem;
  box-sizing: border-box;
`;

export const ContentNewsBanner = styled.div`
  border-radius: 0.625rem;
  width: 17.5rem;
  height: 30.313rem;
  margin: 2.313rem auto 5rem;
  box-shadow: 1px 2px 8px 5px rgba(0, 0, 0, 0.09);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1rem;
  background-color: var(--white-color);
  img {
    width: 100%;
  }
  button {
    color: var(--first-color);
    padding: 0;
    width: max-content;
    padding-left: 1rem;
  }
`;

export const SpanDate = styled.span`
  position: absolute;
  width: 3.375rem;
  height: 3.375rem;
  margin-top: -2.25rem;
  margin-left: 1rem;
  background-color: var(--second-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  color: var(--white-color);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.625rem;
`;

export const ContentNewsBannerTexts = styled.div`
  padding: 0.75rem 1rem 1rem;

  span {
    color: var(--ninth-color);
    font-size: 0.938rem;
  }

  h4 {
    font-size: 1.25rem;
    margin: 0.688rem 0 0.438rem;
  }
  p {
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`;

export const PodcastBox = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  height: auto;
`;

export const PodcastBannerContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: var(--white-color);
  padding-top: 6.813rem;
  padding-bottom: 2.5rem;
  padding-right: 3rem;
  width: 55%;
  max-width: 26.813rem;
  @media only screen and (min-width: 37.5rem) {
    margin-left: -5rem;
  }
  @media only screen and (min-width: 62.5rem) {
    padding-top: 3.75rem;
    padding-bottom: 5.625rem;
    margin-left: -20rem;
  }
  img {
    width: 15.938rem;
    max-width: 26.838rem;
    @media only screen and (min-width: 62.5rem) {
      width: 20rem;
    }
    @media only screen and (min-width: 85.375rem) {
      width: 26.838rem;
    }
  }
  p {
    margin: 3.438rem 0;
    width: 9.125rem;
    font-size: 1.25rem;
    @media only screen and (min-width: 37.5rem) {
      width: 15rem;
    }
    @media only screen and (min-width: 62.5rem) {
      width: 25.25rem;
      margin: 1.875rem 0;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    max-width: fit-content;
    padding: 0.2rem 1.375rem;
  }
`;

export const HandImgBox = styled.div`
  height: 100%;
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: auto;
  margin-right: -20rem;
  @media only screen and (min-width: 37.5rem) {
    margin-right: -33rem;
  }
  @media only screen and (min-width: 85.375rem) {
    position: absolute;
    margin-right: -42.063rem;
  }
  img {
    width: 21rem;
    max-width: 45rem;
    @media only screen and (min-width: 37.5rem) {
      width: 33rem;
    }
    @media only screen and (min-width: 62.5rem) {
      width: 40rem;
    }
    @media only screen and (min-width: 85.375rem) {
      width: 45rem;
    }
  }
`;

export const VideoBannerBox = styled.div`
  display: flex;
  justify-content: flex-end;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
`;

export const VideoBannerContent = styled.div`
  width: 7.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: var(--white-color);
  padding: 8.375rem 2rem 4.875rem;
  @media only screen and (min-width: 37.5rem) {
    width: 12.5rem;
  }
  @media only screen and (min-width: 62.5rem) {
    width: 23rem;
    padding: 8.375rem 3.125rem 4.875rem;
  }
  span {
    width: 100%;
  }
  h2 {
    font-size: 1.25rem;
    margin: 0.5rem 0 1rem;
    color: var(--white-color);
    text-align: left;
    @media only screen and (min-width: 50rem) {
      font-size: 2rem;
    }
    @media only screen and (min-width: 62.5rem) {
      font-size: 3rem;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    max-width: fit-content;
    padding: 0;
    border: none;
  }
`;
