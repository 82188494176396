import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as S from './style';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';

export const Menu = ({readcrumbs}) => {
  const [menuOptions] = useState([
    {
      id: 1,
      title: 'MILHO',
      path: '/comercialDeGraos/',
    },
    {
      id: 2,
      title: 'SOJA',
      path: '/comercialDeGraos/soy',
    },
    {
      id: 3,
      title: 'SORGO',
      path: '/comercialDeGraos/sorghum',
    },
  ]);

  return (
    <>
      <Breadcrumbs
        content={readcrumbs}
        style={{
          color: '#fff',
        }}
      />
      <S.Container>
        {menuOptions.map((item) => (
          <NavLink key={item.id} to={item.path}>
            {item.title}
          </NavLink>
        ))}
      </S.Container>
    </>
  );
};
