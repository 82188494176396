import React from "react";
import Modal from "@mui/material/Modal";

const styleModal = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
};
const style = {
  maxWidth: "80%",
  maxHeight: "80%",
};

export const ModalCustom = ({ open, handleClose, children }) => {
  return (
    <Modal
      style={styleModal}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style}>{children}</div>
    </Modal>
  );
};
