import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video ,button{
    margin: 0;
    padding: 0;
    border: 0;
}
/* HTML5 display-role reset for older browsers */

body{
    font-family: 'Inter', sans-serif;
    font-size: 0.75rem;
    --first-color:#0f4e2b;
    --second-color:#00893a;
    --third-color:#c7d300;
    --fourth-color:#66b336;
    --fifth-color:#b2d7af;
    --sixth-color:#fbb81c;
    --seventh-color:#ffdd00;
    --octave-color:#838283;
    --ninth-color: #D5A058;
    --tenth-color:#F0F2E2;
    --eleventh-color:#BF9D85; 
    --twelfth-color:#C1D0AE;
    --Thirteenth-color: #F0F2E2;
    --white-color:#fff;
    --black-color:#616160;
    --linear-gradient:(180deg, #4ED209 0%, #13A640 52.6%, #066831 100%);
}

a,button{
    cursor: pointer;
    text-decoration: none;
    /* outline: none; */
    border: none;
    background-color: transparent;
}

input,select{
    /* outline: none; */
    background-color: transparent;
}

ul,li{
    list-style:none;
}

a:visited{
    color:inherit;
}

.App{
    max-width:2000px ;
    min-width:360px;
    margin: 0 auto;

    overflow: hidden;
   }

   h1,h2,h3,h4,h5,h6{
    line-height: normal;
 }  


`;
