import styled from 'styled-components';

export const Container = styled.div`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h2`
  margin-bottom: 2.25rem;
  color: var(--black-color);
  text-align: center;
  text-transform: uppercase;

  ::after {
    content: '';
    display: block;
    height: 0.3rem;
    background-color: var(--sixth-color);
    border-radius: 0.5rem;
    margin: 0 auto;
    margin-top: 0.3rem;
    width: 6.25rem;
  }
`;
export const ImgGovernance = styled.img`
  width: 90%;
`;
