/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ShareIcon from '@mui/icons-material/Share';
import { SocialMediaButtons } from '../../../../components/SocialMediaButtons';
import { useHeaderStates } from '../../../../Providers/HeaderStates';
import { useStartupState } from '../../../../Providers/StartupState';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { Loading } from '../../../../components/Loading';
import {
  baseUrlImg,
  handleGetData,
  handleGetToken,
} from '../../../../Services';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { MdodalPhotos } from '../MdodalPhotos';
import * as S from './style';

export const Photos = () => {
  const [openSocialMedia, setOpenSocialMedia] = useState(false);
  const [data, setData] = useState(null);
  const [token, setToken] = useState('');

  const { setError, loading, setLoading } = useStartupState();
  const { setTitle, setBgHeader } = useHeaderStates();
  const navigate = useNavigate();

  const { idPhoto } = useParams();

  useEffect(() => {
    handleGetToken()
      .then((response) => {
        setToken(response?.data?.access_token);
        if (response?.status !== 200) {
          throw new Error(response.message);
        }
      })
      .catch((e) => {
        console.log(e.message);
        setError(e.message);
      });
  }, []);

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(
        token,
        `api/content/copasul/albumfotos/?&$filter=data/idUrl/iv eq '${idPhoto}'`,
      )
        .then((response) => {
          if (response?.data?.items[0]?.data) {
            setData(response?.data?.items[0]?.data);
           
          } else {
            navigate('/galeriaDeFotos');
          }
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.tituloPagina?.iv);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  const handleCloseSocialMedia = () => {
    if (openSocialMedia) {
      setOpenSocialMedia(false);
    }
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={`${data?.Breadcrumbs?.iv} > Álbum`}
          style={{ position: 'absolute', zIndex: '1' }}
        />
        <Routes >
          <Route
            path={`/fotos/:index`}
            element={<MdodalPhotos data={data} />}
            end
          />
        </Routes>
        <S.Container
          bgOurUnits={data?.imagemBackground?.iv[0]}
          onClick={handleCloseSocialMedia}
        >
          <S.ContentBox>
            <S.HeaderPage>
              {data?.dataAlbum && (
                <span>
                  {format(parseISO(data?.dataAlbum?.iv), 'dd, MMM yyyy', {
                    locale: ptBR,
                  })}
                </span>
              )}
            </S.HeaderPage>
            <h3>{data?.tituloAlbum?.iv}</h3>
            <ReactMarkdown children={data?.descricaoAlbum?.iv} />
            <S.CardsBox>
              {data &&
                data?.fotosAlbum?.iv.map((item, index) => (
                  <img
                    src={`${baseUrlImg}${item}`}
                    alt=""
                    onClick={() => navigate(`/fotos/${idPhoto}/fotos/${index}`)}
                    key={index}
                  />
                ))}
            </S.CardsBox>
            <S.FooterPage>
              {openSocialMedia && (
                <SocialMediaButtons
                  open={() => setOpenSocialMedia(!openSocialMedia)}
                  url={`http://www.copasul.coop.br/fotos/${idPhoto}`}
                />
              )}
              <button
                onClick={() => setOpenSocialMedia(!openSocialMedia)}
                style={{ gap: '0.5rem' }}
              >
                <ShareIcon /> Compartilhar
              </button>
              <button
                onClick={() => navigate(`/galeriaDeFotos`)}
              >
                <ArrowBackIosIcon /> Voltar
              </button>
            </S.FooterPage>
          </S.ContentBox>
        </S.Container>
      </div>
    );
  }
};
