import styled from 'styled-components';

export const Container = styled.div`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  padding-top: 1rem;
`;

export const ImgFirst = styled.div`
  width: 100%;
  font-size: 1.25rem;

  p {
    max-width: 44.75rem;
    width: 80%;
    margin: 0 auto 1.5rem;
    text-align: center;
  }
  span {
    display: block;
    width: 60% !important;
    max-width: 17.625rem;
    text-align: center;
    font-size: 1rem;
    margin: 0 auto;
    @media only screen and (min-width: 800px) {
      font-size: 1.125rem;
    }
  }
`;

export const ImgBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 3.563rem;
  @media only screen and (min-width: 1000px) {
    flex-direction: row;
    justify-content: center;
  }
  img {
    width: 30rem;
    position: absolute;
    @media only screen and (min-width: 800px) {
      width: 35rem;
    }
    @media only screen and (min-width: 1000px) {
      width: 44.813rem;
      position: static;
      margin-top: 4.688rem;
    }
  }
`;

export const ImgBoxContent = styled.div`
  z-index: 5;
  width: 12.813rem;
  padding: 3.5rem 0 6.5rem;
  margin-left: -110px;
  font-family: 'Prompt';
  @media only screen and (min-width: 600px) {
    width: 20rem;
    margin-left: -50px;
    padding-top: 5rem;
  }
  @media only screen and (min-width: 800px) {
    width: 20rem;
    margin-left: -250px;
    padding-top: 7rem;
  }
  @media only screen and (min-width: 1000px) {
    padding: 0rem;
    width: 27.5rem;
    margin: 0;
    margin-right: -500px;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    line-height: 130%;
    @media only screen and (min-width: 600px) {
      width: 20rem;
      margin-left: -70px;
    }
    @media only screen and (min-width: 800px) {
      margin-left: 0px;
    }
    @media only screen and (min-width: 1000px) {
      margin-left: -40px;
    }
  }
  p {
    width: 9.375rem;
    font-size: 0.813rem;
    line-height: 115%;
    margin: 0;
    text-align: left;
    @media only screen and (min-width: 800px) {
      font-size: 1.25rem;
      width: 16.75rem;
    }
  }
`;

export const ImgSecond = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  margin-top: 6rem;
  margin-bottom: 3.75rem;
  @media only screen and (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (min-width: 1000px) {
    justify-content: space-between;
  }
  @media only screen and (min-width: 1366px) {
    justify-content: center;
    gap: 24.5rem;
  }
`;

export const backgroundImg = styled.img`
  width: 40rem;
  margin-left: -17.375rem;
  z-index: 0;
  @media only screen and (min-width: 600px) {
    width: 100%;
  }
  @media only screen and (min-width: 800px) {
    width: auto;
    height: 100%;
    position: absolute;
    margin-left: 0;
    object-fit: cover;
  }
  @media only screen and (min-width: 1126px) {
    width: 100%;
    height: auto;
  }
`;

export const WritingImg = styled.img`
  z-index: 2;
  height: 20.25rem;
  margin-right: 2.5rem;
  margin-top: -4.625rem;
  position: absolute;
  @media only screen and (min-width: 600px) {
    margin-top: -50px;
  }
  @media only screen and (min-width: 800px) {
    position: relative;
    z-index: 2;
    margin: 1.563rem 0;
    margin-left: 15rem;
    :nth-child(3) {
      order: 3;
    }
  }
  @media only screen and (min-width: 1000px) {
    margin-right: 8em;
  }
  @media only screen and (min-width: 1126px) {
    margin-right: 5em;
  }
  @media only screen and (min-width: 1366px) {
    margin-right: 0;
    margin-left: 0;
    height: 25.503rem;
  }
`;

export const ImgSecondText = styled.div`
  margin-top: 3.563rem;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  :nth-child(2) {
    order: 3;
  }
  @media only screen and (min-width: 800px) {
    z-index: 2;
    margin-left: -60px;
    max-width: 27.188rem;
  }
  @media only screen and (min-width: 1000px) {
    margin: 0;
    margin-left: 8em;
    text-align: left;
  }
  @media only screen and (min-width: 1366px) {
    margin-left: 0;
  }
  h2 {
    margin-bottom: 1.5rem;
    padding: 0 2.5rem;
    max-width: 28.125rem;
    font-size: 1.25rem;
    @media only screen and (min-width: 1000px) {
      padding: 0;
      font-size: 2rem;
    }
  }
  p {
    display: block;
    width: 90%;
    max-width: 27.188rem;
    font-size: 1rem;
    @media only screen and (min-width: 1000px) {
      font-size: 1.125rem;
      width: auto;
    }
  }
`;

export const RotatingBannerBox = styled.div`
  max-width: 56.375rem;
  width: 90%;
  margin: 2.688rem auto 6.813rem;
  .slick-prev,
  .slick-next {
    path {
      display: none;
      fill: var(--fourth-color);
      @media only screen and (min-width: 1100px) {
        display: block;
      }
    }
  }
  h3 {
    font-weight: 400;
    margin: 2.188rem 0;
    color: var(--white-color);
    font-size: 1rem;
    text-align: center;
    @media only screen and (min-width: 800px) {
      font-size: 1.25rem;
    }
  }
`;

export const RotatingBannercontent = styled.div`
  border: 1px solid var(--fourth-color);
  border-radius: 0.625rem;
  padding: 0.625rem;
  margin: 0 auto;
  box-sizing: border-box;
  img {
    width: auto;
    height:25rem;
    border-radius: 0.625rem;
    margin: 0 auto;
  }
  p {
    text-align: center;
    margin: 1rem 0;
    font-size: 1rem;
  }
`;
