import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ bgOurUnits }) => css`
    background: url(${bgOurUnits});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 5.625rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
    color: var(--black-color);
  `}
`;

export const DLBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  position: relative;
  margin-bottom: 3rem;
  font-size: 1rem;
  color: var(--white-color);
  width: 18.75rem;
  @media only screen and (min-width: 600px) {
    justify-content: center;
    width: 90%;
    align-items: flex-start;
    position: static;
  }

  img {
    width: 6.688rem;
    position: absolute;
    max-width: 16rem;
    @media only screen and (min-width: 600px) {
      width: 15rem;
      position: static;
    }
    @media only screen and (min-width: 800px) {
      width: 16rem;
    }
  }
`;

export const DLLinkBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin: 0 auto;

  @media only screen and (min-width: 600px) {
    width: 45%;
    margin: 0;
    display: block;
  }
  @media only screen and (min-width: 800px) {
    max-width: 28.875rem;
  }

  h3 {
    width: 60%;
    margin-bottom: 5rem;
    @media only screen and (min-width: 600px) {
      width: 100%;
      max-width: 18.25rem;
      margin-bottom: 2.3125rem;
    }
  }
  p {
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 100%;

    @media only screen and (min-width: 600px) {
      width: 90%;
      margin: 0;
      margin-bottom: 2rem;
      max-width: 28.875rem;
    }
    :nth-child(2) {
      margin-bottom: 1rem;
    }
    :nth-child(3) {
      margin-bottom: 3.375rem;
    }
  }
`;

export const SpotifyButtonBox = styled.div`
  width: 100%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: var(--white-color);
    border: 1px solid var(--white-color);
    padding: 0.625rem 1.5625rem;
    max-width: 8.6875rem;
    font-size: 1rem;
    border-radius: 0.625rem;
    margin-top: 1.75rem;
  }
  div {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    margin-right: 1rem;
  }
  p {
    margin-bottom: 1.125rem;
  }
`;

export const RadioBox = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  svg {
    height: 100%;
  }
  p {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    span {
      font-size: 0.75rem;
    }
  }
`;
