import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import * as S from './style';

export const SocialMediaButtons = ({ url, text, open, ...props }) => (
  <S.WrapShareButtons onClick={open} {...props}>

    <FacebookShareButton
      className="SocialMediaShareButton"
      url={url}
      quote={text}
    >
      <button className="facebook">
        <FacebookIcon />
      </button>
    </FacebookShareButton>

    <LinkedinShareButton
      className="SocialMediaShareButton"
      url={url}
      quote={text}
    >
      <button className="linkedin">
        <LinkedInIcon />
      </button>
    </LinkedinShareButton>

    <WhatsappShareButton
      className="SocialMediaShareButton"
      url={url}
      quote={text}
    >
      <button className="whatsApp">
        <WhatsAppIcon />
      </button>
    </WhatsappShareButton>

    <TelegramShareButton
      className="SocialMediaShareButton"
      url={url}
      quote={text}
    >
      <button className="telegram">
        <TelegramIcon />
      </button>
    </TelegramShareButton>

    <TwitterShareButton
      className="SocialMediaShareButton"
      url={url}
      title={text}
    >
      <button className="twitter">
        <TwitterIcon />
      </button>
    </TwitterShareButton>

    <EmailShareButton
      className="SocialMediaShareButton"
      subject={`Check out what I did on GoodWerk`}
      body={`${text}: ${url}`}
    >
      <button className="email">
        <EmailIcon />
      </button>
    </EmailShareButton>
  </S.WrapShareButtons>
);
