/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useStartupState } from '../../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../../Services';
import { useForm } from 'react-hook-form';
import logoCopasulDefault from '../../../assets/Logo_copasul.svg';
import logoCopasulWhiteDefault from '../../../assets/Logo_copasul_white.svg';
import { Modal } from '@mui/material';
import * as S from './style';

export const MenuMobile = ({
  title,
  bgHeader,
  menu,
  setMenu,
  optionsMenu,
}) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [data, setData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [logoCopasulWhite, setLogoCopasulWhite] = useState('');
  const [logoCopasul, setLogoCopasul] = useState('');

  const navigate = useNavigate();
  const { register } = useForm();
  const { token, setError, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/layoutheader/')
        .then((response) => {
          setData(response?.data?.items[0]?.data?.header?.iv);
          
          response?.data?.items[0]?.data?.header?.iv?.logoBranca[0]
          ? setLogoCopasulWhite(
              response?.data?.items[0]?.data?.header?.iv?.logoBranca[0].toString(),
            )
          : setLogoCopasulWhite(logoCopasulWhiteDefault);

        response?.data?.items[0]?.data?.header?.iv?.logoVerde[0]
          ? setLogoCopasul(
              response?.data?.items[0]?.data?.header?.iv?.logoVerde[0].toString(),
            )
          : setLogoCopasul(logoCopasulDefault);

          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setOpenMobileMenu(false);
    menu.forEach((item, i) => {
      item.status = false;
    });
  }, [navigate]);

  const handleMenu = (index, route) => {
    navigate(route);
    let updateMenu = [...menu];

    updateMenu.forEach((item, i) => {
      item.status = i === index ? !item.status : false;
    });

    setMenu(updateMenu);
  };

  const handleOpemModal = () => {
    setOpenModal(!openModal);
  };

  const handleSelectChange = (e) => {
    setOpenModal(false);
    navigate(e.target.value);
  };

  return (
    <S.HeaderBox src={`${baseUrlImg}${bgHeader}`}>
      {openMobileMenu && (
        <S.BackgroundSubMenu onClick={() => setOpenMobileMenu(false)} />
      )}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <S.SearchBox src={bgHeader}>
          <p>BUSCAR</p>
          <div>
            <SearchIcon />
            <select
              id="selectField"
              {...register('selectField')}
              onChange={handleSelectChange}
            >
              <option value="" disabled>
                Faça sua busca aqui
              </option>
              {optionsMenu.map((item, index) => (
                <option key={index} value={item?.route}>
                  {item?.title}
                </option>
              ))}
            </select>
          </div>
        </S.SearchBox>
      </Modal>

      <S.FirstBox src={bgHeader}>
        <button>
          <S.LogoImg
            src={
              bgHeader
                ? `${baseUrlImg}${logoCopasulWhite}`
                : `${baseUrlImg}${logoCopasul}`
            }
            alt="Logo copasul"
            onClick={() => navigate('/')}
          />
        </button>
        <S.ButtonsBox>
          <S.SearchBoxMobile src={bgHeader}>
            <button>
              <SearchIcon onClick={handleOpemModal} />
            </button>
            <button onClick={() => setOpenMobileMenu(!openMobileMenu)}>
              {openMobileMenu ? <CloseIcon /> : <MenuIcon />}
            </button>
          </S.SearchBoxMobile>
        </S.ButtonsBox>
      </S.FirstBox>
      <S.SecondBox borderBottom={title} src={bgHeader}>
        <S.SecondNav open={openMobileMenu}>
          {menu.map((item, index) => {
            return (
              <div style={{ position: 'relative' }} key={index}>
                <S.MenuButton key={item.title}>
                  <p onClick={() => handleMenu(index, item?.menu?.rota)}>
                    {item?.menu?.tituloMenu}{' '}
                    {item?.menu?.subMenu?.length > 0 && <ArrowDropDownIcon />}
                  </p>
                  {item?.menu?.subMenu?.length > 0 && item.status && (
                    <S.SubMenu>
                      {item?.menu?.subMenu?.map((subItem) => (
                        <NavLink key={subItem.title} to={subItem?.rotaSubmenu}>
                          {subItem?.tituloSubmenu}
                        </NavLink>
                      ))}
                    </S.SubMenu>
                  )}
                </S.MenuButton>
              </div>
            );
          })}
          <S.NavLinkBox>
            <NavLink
              to={`${data?.botoes[0]?.linkBotao?.linkExterno}`}
              target="_blank"
            >
              {data?.botoes[0]?.linkBotao?.tituloLink}
            </NavLink>
            <NavLink
              to={`${data?.botoes[1]?.linkBotao?.linkExterno}`}
              target="_blank"
            >
              {data?.botoes[1]?.linkBotao?.tituloLink}
            </NavLink>
          </S.NavLinkBox>
          {/* <S.LanguageButtonBox>
            <button className="active">PT</button>
            <button>EN</button>
            <button>ES</button>
          </S.LanguageButtonBox> */}
        </S.SecondNav>
      </S.SecondBox>
      <S.TitlePage paddingHidden={title}>{title}</S.TitlePage>
    </S.HeaderBox>
  );
};
