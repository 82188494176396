import styled from 'styled-components';

export const Container = styled.div`
  color: var(--white-color);
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 6rem;
  padding-top: 5rem;
`;

export const ContentBannersBox = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 44.813rem;

  p,
  img {
    margin-bottom: 1.25rem;
    font-size: 1rem;
  }
  img {
    width: 100%;
    border-radius: 0.625rem;

    @media only screen and (min-width: 600px) {
      display: none;
    }
  }
`;
