import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  background-color: var(--tenth-color);
  padding: 6rem 0;
  background: linear-gradient(180deg, var(--tenth-color) 0%, #dde0cb 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  gap: 3rem;
  @media only screen and (min-width: 867px) {
    gap: 7rem;
  }
`;

export const LeftFormBox = styled.div`
  width: 100%;
  max-width: 25rem;
`;

export const HeaderFormBox = styled.header`
  color: var(--black-color);
  margin-bottom: 3.375rem;
  h2 {
    align-items: center;
    font-size: 2.25rem;
    display: flex;
    gap: 1rem;
  }
  span {
    font-size: 0.875rem;
  }
  svg {
    color: var(--second-color);
    font-size: 2.8125rem;
  }
`;

export const Form = styled.form`
  position: relative;
  input,
  textarea,
  select {
    background-color: var(--white-color);
    border-radius: 10px;
    width: 100%;
    height: 2.68rem;
    border: none;
    padding-left: 1rem;
    box-sizing: border-box;
  }
  .boxInput {
    margin-bottom: 1.5rem;
  }

  .textarea {
    margin-bottom: 2rem;
    textarea {
      height: 11.8rem;
      resize: none;
      padding: 1rem;
    }
  }

  .error {
    margin-bottom: 0%.5rem;
    color: #f00;
    position: absolute;
  }

  button {
    background-color: var(--second-color);
    color: var(--white-color);
    font-size: 0.875rem;
    padding: 0.56rem 4.063rem;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    @media only screen and (min-width: 797px) {
      display: inline-block;
      margin: 0;
    }
  }
`;

export const RightFormBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 16.2rem;
  font-size: 1rem;
  padding-top: 3rem;
  color: var(--black-color);
  h3 {
    margin-bottom: 1.25rem;
  }
`;

export const InfoBox = styled.div`
  border: 1px solid var(--second-color);
  padding: 1.56rem;
  color: var(--second-color);
  width: fit-content;
  border-radius: 10px;
  margin-top: 1rem;
  font-size: 0.813rem;
  p {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    svg {
      margin-right: 0.313rem;
    }
  }
`;

export const NavLinkCustom = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 247px;
  height: 43px;
  margin-bottom: 1.125rem;
  background: var(--white-color);
  box-shadow: 10px 12px 14px rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  color: var(--second-color);
  svg {
    color: var(--seventh-color);
  }
`;

export const MapsBox = styled.div`
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 56.5;
`;
