import styled, { css } from 'styled-components';

export const HeaderBox = styled.header`
  ${({ src }) => css`
    background: ${src ? `url(${src}) no-repeat` : 'transparent'};
    background-size: cover;
  `}
`;

export const FirstBox = styled.div`
  ${({ src }) => css`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem 3.125rem;

    select {
      border: none;
      color: ${src ? ' var(--white-color)' : ' var(--first-color)'};
      font-size: 0.875rem;
      margin-left: 26px;
      font-weight: bold;
    }
  `}
`;

export const SearchBox = styled.div`
  background-color: var(--fourth-color);
  width: 323px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 1rem;
  color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  p {
    font-size: 20px;
  }
  div {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-end;
    svg {
      position: absolute;
      margin-right: 0.625rem;
      color: var(--black-color);
    }
    select {
      height: 30px;
      width: 100%;
      border-radius: 0.625rem;
      border: 1px solid;
      border-color: ${({ src }) =>
        src ? 'var(--white-color);' : 'var(--octave-color);'};
      color: ${({ src }) =>
        src ? 'var(--white-color);' : 'var(--octave-color);'};
      font-size: 0.875rem;
      padding: 0 0.625rem;
      padding-left: 2.5rem;
      background-color: #fff;
      option {
        color: var(--octave-color);
        margin: 0.5rem auto;
      }
    }
  }
`;

export const SearchBoxMobile = styled.div`
  ${({ src }) => css`
    display: flex;
    align-items: center;
    gap: 1.2rem;
    svg {
      margin-left: 0;
      color: ${src ? 'var(--white-color);' : 'var(--second-color)'};
    }
  `}
`;

export const LogoImg = styled.img`
  width: 5.75rem;
`;
export const ButtonsBox = styled.div`
  align-items: center;
  display: flex;
  gap: 0.625rem;
`;

export const SecondBox = styled.div`
  ${({ borderBottom, src }) => css`
    position: relatrive;
    display: flex;
    border-top: 1px solid var(--second-color);
    ${borderBottom && 'border-bottom: 1px solid var( --second-color);'}
    justify-content: flex-end;
    border-color: ${src ? 'var(--white-color)' : 'var(--second-color)'};
  `}
`;

export const SecondNav = styled.nav`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  z-index: 10;
  position: absolute;
  justify-content: space-between;
  background-color: var(--Thirteenth-color);
  padding: 21px 27px;
  box-sizing: border-box;
  gap: 1rem;
  margin-top: -1px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 0px 10px;
`;

export const MenuButton = styled.button`
  color: var(--first-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem !important;
  height: 100%;
  border-bottom: 0.5px solid var(--twelfth-color);
  width: 100%;
  padding-bottom: 0.5rem;
  p {
    display: flex;
    align-items: center;
  }
  svg {
    color: var(--second-color);
  }
`;

export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 0;
  padding-left: 1rem;
  gap: 1rem;
  text-align: left;
  a {
    color: var(--second-color);
    :visited {
      color: var(--second-color);
    }
  }
`;

export const TitlePage = styled.h1`
  ${({ paddingHidden }) => css`
    ${paddingHidden && 'padding: 1.25rem 2rem;'}
    text-align: center;
    color: var(--white-color);
    font-size: 2rem;
  `}
`;

export const NavLinkBox = styled.div`
  display: grid;
  gap: 1rem;
  a {
    font-size: 1rem;
    font-weight: 600;
    :nth-child(1) {
      color: var(--second-color);
    }
    :nth-child(2) {
      color: var(--fourth-color);
    }
  }
`;

export const LanguageButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  button {
    font-weight: 600;
    color: var(--first-color);
    padding: 0.5rem;
  }

  .active {
    background-color: var(--twelfth-color);
    border-radius: 5px;
  }
`;

export const BackgroundSubMenu = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 10;
`;
