import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ bgOurUnits }) => css`
    background: url(${bgOurUnits});
    padding: 5.625rem 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 1rem;
    color: var(--white-color);
  `}
`;

export const Card = styled.div`
  background: linear-gradient(180deg, #4ed209 0%, #13a640 52.6%, #066831 100%);
  box-shadow: 1px 2px 8px 5px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  margin: 0 auto 2rem;
  width: 100%;
  max-width: 30rem;
  @media only screen and (min-width: 683px) {
    width: 47%;
    max-width: 20.5rem;
  }
  @media only screen and (min-width: 1000px) {
    width: 30%;
    max-width: 20.5rem;
  }
  img {
    width: 100%;
  }
`;

export const ContentCard = styled.div`
  padding: 1rem 1.5rem 2rem;
  width: 100%;
  box-sizing: border-box;
  h3 {
    font-size: 1.25rem;
    margin-bottom: 1.563rem;
  }
`;

export const Paragraph = styled.div`
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;

  width: 100%;
  box-sizing: border-box;

  .iconBox {
    width: 25px;
    height: 25px;
  }

  p {
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    word-break: break-all;
  }
  :nth-last-child(1) {
    margin-bottom: 0;
    p {
      display: flex;
      flex-direction: column;
      span {
        font-size: 0.8rem;
      }
    }
  }
`;
