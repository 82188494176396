import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ bgOurUnits }) => css`
    background: url(${bgOurUnits});
    position: relative;
    padding: 5.625rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: var(--black-color);
  `}
`;

export const ContentBox = styled.div`
  background-color: var(--white-color);
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  line-height: 2rem;
  box-shadow: 1px 2px 8px 5px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  max-width: 56.25rem;

  @media only screen and (min-width: 1366px) {
    padding: 3.125rem 4.625rem;
  }
  border-radius: 10px;
  h2 {
    color: var(--second-color);
    font-size: 1.25rem;
  }
  h3 {
    width: 90%;
    text-align: center;
    margin-bottom: 3.75rem;
    font-size: 1.5rem;
    max-width: 31.25rem;
  }
  h5 {
    margin-bottom: 2.188rem;
    text-align: center;
    max-width: 27.125rem;
  }
  img {
    width: 90%;
    max-width: 33.125rem;
    border-radius: 0.625rem;
  }
  p {
    width: 90%;
    font-size: 0.875rem;
  }

  .span {
    font-size: 0.813rem;
    text-align: center;
    width: 70%;
    max-width: 25rem;
    line-height: 135%;
  }
  .ContentBoxSpan {
    margin-bottom: 3rem;
  }

  .legendSpan {
    margin-bottom: 2.5rem;
  }
  .phase {
    text-align: center;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 500;
    font-family: 'Inter';
    line-height: 100%;
    margin: 2rem 0 4rem;
  }
`;

export const HeaderPage = styled.header`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--fourth-color);
  color: var(--fourth-color);
  width: 100%;
  font-size: 0.813rem;
  margin-bottom: 1rem;
`;

export const FooterPage = styled(HeaderPage)`
  border: none;
  margin: 0;
  border-top: 1px solid var(--fourth-color);
  margin-top: 2rem;
  padding-top: 1.25rem;
  button {
    display: flex;
    align-items: center;
    color: var(--fourth-color);
    svg {
      font-size: 1rem;
    }
  }
`;

export const SocialMediaBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.5);
`;
