/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ShareIcon from '@mui/icons-material/Share';
import { SocialMediaButtons } from '../../../../components/SocialMediaButtons';
import { handleGetData, handleGetToken } from '../../../../Services';
import { useHeaderStates } from '../../../../Providers/HeaderStates';
import { useStartupState } from '../../../../Providers/StartupState';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import { Loading } from '../../../../components/Loading';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import HtmlParser from 'html-react-parser';
import * as S from './style';

export const PageNew = () => {
  const [openSocialMedia, setOpenSocialMedia] = useState(false);
  const [data, setData] = useState(null);
  const [token, setToken] = useState('');

  const { setTitle, setBgHeader } = useHeaderStates();
  const { setError, loading, setLoading } = useStartupState();
  const navigate = useNavigate();

  const { idNew } = useParams()

  const handleCloseSocialMedia = () => {
    if (openSocialMedia) {
      setOpenSocialMedia(false);
    }
  };

  useEffect(() => {
    handleGetToken()
      .then((response) => {
        setToken(response?.data?.access_token);
        if (response?.status !== 200) {
          throw new Error(response.message);
        }
      })
      .catch((e) => {
        console.log(e.message);
        setError(e.message);
      });
  }, []);

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(
        token,
        `api/content/copasul/noticia/?&$filter=data/idUrl/iv eq '${idNew}'`,
      )
        .then((response) => {
          if (response?.data?.items[0]?.data) {
            setData(response?.data?.items[0]?.data);
          } else {
            navigate('/noticias');
          }
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          navigate('/noticias')
          console.log(e.message); 
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle('Notícia');
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.Breadcrumbs?.iv}
          style={{ position: 'absolute', zIndex: '1' }}
        />
        <S.Container
          bgOurUnits={data?.imagemBackground?.iv[0]}
          onClick={handleCloseSocialMedia}
        >
          <S.ContentBox>
            <S.HeaderPage>
              {data?.dataNoticia && (
                <span>
                  {format(parseISO(data?.dataNoticia?.iv), 'dd, MMM yyyy', {
                    locale: ptBR,
                  })}
                </span>
              )}
              {data?.type && <span>{data?.type?.iv.toUpperCase()}</span>}
            </S.HeaderPage>
            <S.NewBoxe>
              {data?.conteudoHtml && HtmlParser(data?.conteudoHtml?.iv)}
            </S.NewBoxe>
            <S.FooterPage>
              {openSocialMedia && (
                <SocialMediaButtons
                  open={() => setOpenSocialMedia(!openSocialMedia)}
                  url={`http://www.copasul.coop.br/paginaNoticia/${idNew}`}
                />
              )}
              <button
                onClick={() => setOpenSocialMedia(!openSocialMedia)}
                style={{ gap: '0.5rem' }}
              >
                <ShareIcon /> Compartilhar
              </button>
              <button onClick={() => navigate('/noticias')}>
                <ArrowBackIosIcon /> Voltar
              </button>
            </S.FooterPage>
          </S.ContentBox>
        </S.Container>
      </div>
    );
  }
};
