/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useHeaderStates } from '../../Providers/HeaderStates';
import CollectionsIcon from '@mui/icons-material/Collections';
import { useNavigate } from 'react-router-dom';
import { useStartupState } from '../../Providers/StartupState';
import { useForm } from 'react-hook-form';
import { baseUrlImg, handleGetData } from '../../Services';
import { Loading } from '../../components/Loading';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import * as S from './style';

export const PhotoGallery = () => {
  const [search, setSearch] = useState('');
  const [data, setData] = useState(null);
  const [top, setTop] = useState(10);

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();
  const navigate = useNavigate();
  const { handleSubmit, register, watch } = useForm();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(
        token,
        `api/content/copasul/albumfotos/?$top=${top}&?$search=albumfotos`,
      )
        .then((response) => {
          setData(response?.data?.items);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData([]);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, top]);

  useEffect(() => {
    setTitle(data && data[0]?.data?.tituloPagina?.iv);
    setBgHeader(data && data[0]?.data?.imagemHeader?.iv[0]);
  });

  useEffect(() => {
    if (watch('title') === '') {
      setSearch('');
    }
  }, [watch('title')]);

  const handleOnSubmit = (title) => {
    if (title.title !== '') {
      setSearch(title.title);
    }
    return;
  };

  const handlePhoto = (data) => {
    navigate(`/fotos/${data}`);
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data && data[0]?.data?.Breadcrumbs?.iv}
          style={{ position: 'absolute' }}
        />
        <S.Container
          bgOurUnits={data && data[0]?.data?.imagemBackground?.iv[0]}
        >
          <S.FormSearch onSubmit={handleSubmit(handleOnSubmit)}>
            <S.SearchBox>
              <SearchIcon />
              <input
                type="text"
                placeholder="Faça sua busca aqui"
                {...register('title', {})}
              />
            </S.SearchBox>
            <S.LoadMoreButton bgColor={false}>Buscar</S.LoadMoreButton>
          </S.FormSearch>
          <S.CardsBox>
            {data &&
              data
                .filter((item) => {
                  if (!search) return true;
                  const title = item?.data?.tituloAlbum?.iv.toLowerCase();

                  return title.includes(search.toLowerCase());
                })
                .map((item) => (
                  <S.ContentNewsBanner
                    key={item?.schemaId}
                    onClick={() => handlePhoto(item?.data?.idUrl?.iv)}
                    >
                    <S.SpanDate>
                      {format(new Date(item?.data?.dataAlbum?.iv), 'dd MMM', {
                        locale: ptBR,
                      })}
                    </S.SpanDate>
                    <img
                      src={`${baseUrlImg}${item?.data?.fotosAlbum?.iv[0]}`}
                      alt="imagem de capa"
                    />
                    <S.CollectionsIconBox>
                      <CollectionsIcon />
                    </S.CollectionsIconBox>
                    <S.ContentNewsBannerTexts>
                      <h4 title={item?.data?.tituloAlbum?.iv}>
                        {item?.data?.tituloAlbum?.iv}
                      </h4>
                      <p>{item?.data?.descricaoAlbum?.iv}</p>
                    </S.ContentNewsBannerTexts>
                  </S.ContentNewsBanner>
                ))}
          </S.CardsBox>
          <S.LoadMoreButton bgColor={true} onClick={() => setTop(top + 10)}>
            Carregar mais
          </S.LoadMoreButton>
        </S.Container>
      </div>
    );
  }
};
