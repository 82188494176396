/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { LatestNews } from '../../components/LatestNews';
import ReactPlayer from 'react-player';
import { RotatingBanner } from '../../components/RotatingBanner';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../Services';
import { Loading } from '../../components/Loading';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import * as S from './style';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export const BuildingSoils = () => {
  const [data, setData] = useState(null);
  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/construindosolos/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.titulo?.iv);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
      } else if (window.innerWidth >= 800 && window.innerWidth <= 1100) {
      } else if (window.innerWidth > 1100) {
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ color: '#fff', position: 'absolute' }}
        />

        <S.Container src={`${baseUrlImg}${data?.imagemFundo?.iv[0]}`}>
          <S.Logo src={`${baseUrlImg}${data?.imagemLogo?.iv[0]}`} alt="logo construindo solos" />
          <h2>{data?.tituloConteudo?.iv}</h2>
          <ReactMarkdown children={data?.conteudoMarkdown?.iv} />
          <S.Comment>
            <img
              src={`${baseUrlImg}${data?.cartao?.iv?.subConteudo?.imagens[0]?.imagem[0]}`}
              alt="foto de perfil"
            />
            <div>
              <ReactMarkdown children={data?.cartao?.iv?.conteudo} />
            </div>
          </S.Comment>
          <S.SliderBox>
            <RotatingBanner slidesToShow={1} slidesToScroll={1}>
              {data &&
                data?.listaVideos?.iv.map((item) => (
                  <div
                    style={{ padding: '0 1rem' }}
                    key={item?.videoInfo?.schemaId}
                  >
                    <S.VideoBox>
                      <S.ReactPlayerBox>
                        <ReactPlayer
                          className="react-player"
                          url={`${item?.videoInfo?.link?.linkExterno}`}
                          width="100%"
                          height="100%"
                          controls={false}
                          playing={false}
                        />
                      </S.ReactPlayerBox>
                      <p>{item?.videoInfo?.titulo}</p>
                    </S.VideoBox>
                  </div>
                ))}
            </RotatingBanner>
          </S.SliderBox>
          <LatestNews BGColor={false} />
        </S.Container>
      </div>
    );
  }
};
