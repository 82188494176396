import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import logoLoading from '../../assets/logo_loading.png';

export const Loading = () => {
  const LinearIndeterminate = () => {
    return (
      <Box sx={{ width: '30%' }}>
        <LinearProgress
          sx={{
            backgroundColor: 'rgba(255,255,255, 0.5)',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#fff',
            },
          }}
        />
      </Box>
    );
  };

  return (
    <div
      style={{
        height: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '1rem',
        backgroundColor: '#00893A',
      }}
    >
      <img src={logoLoading} alt="" />
      <LinearIndeterminate />
    </div>
  );
};
