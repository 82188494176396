/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import * as S from './style';
import { RotatingBanner } from '../../components/RotatingBanner';
import { ModalCustom } from '../../components/ModalCustom';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../Services';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Loading } from '../../components/Loading';

export const OurHistory = () => {
  const [data, setData] = useState(null);
  const [cardActive, setCardActive] = useState(0);
  const [screenWidth, setScreenWidth] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [srcModal, setSrcModal] = useState(false);

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/nossahistoria/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.seo?.iv?.TituloSEO);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 499) {
        return setScreenWidth(1);
      } else if (window.innerWidth >= 499 && window.innerWidth <= 720) {
        return setScreenWidth(2);
      } else if (window.innerWidth >= 720 && window.innerWidth <= 1000) {
        return setScreenWidth(3);
      } else if (window.innerWidth >= 1000 && window.innerWidth <= 1365) {
        return setScreenWidth(4);
      } else if (window.innerWidth > 1366) {
        return setScreenWidth(5);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleOpenModal = (src) => {
    setOpenModal(!openModal);
    setSrcModal(src);
  };

  const handleActiveCard = (index) => {
    setCardActive(index);
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <S.Container>
        <Breadcrumbs
          content={data?.bannerPrincipal?.iv?.botaoLink?.tituloLink}
          style={{ position: 'absolute' }}
        />
        <ModalCustom open={openModal} handleClose={handleOpenModal}>
          <S.ImgModal src={`${baseUrlImg}${srcModal}`} alt="" />
        </ModalCustom>
        <S.HistoryBox>
          <img
            src={`${baseUrlImg}${data?.bannerPrincipal?.iv?.imagemBackground[0]}`}
            alt="Homens em meio a plantação"
          />
          <S.BannerHistoryBox>
            {data && (
              <ReactMarkdown
                children={data?.bannerPrincipal?.iv?.conteudoMarkdown}
              />
            )}
          </S.BannerHistoryBox>
        </S.HistoryBox>
        <S.TimeLineBox src={`${baseUrlImg}${data?.imagemBackground?.iv[0]}`}>
          <h2 >{data?.subtituloPagina?.iv}</h2>
          <S.CardsBox >
            <RotatingBanner slidesToShow={screenWidth} slidesToScroll={1}>
              {data &&
                data?.linhaDoTempo?.iv.map((item, index) => (
                  <S.Cards key={item?.cartao?.schemaId} >
                    <S.CardsContent
                      onClick={() => handleActiveCard(index)}
                      className={index === cardActive && 'active'}
                    >
                      <p>{item?.cartao?.titulo}</p>
                      <span>{item?.cartao?.subTitulo}</span>
                    </S.CardsContent>
                  </S.Cards>
                ))}
            </RotatingBanner>
          </S.CardsBox>

          <S.TextP>
            {
              <ReactMarkdown
                children={
                  data?.linhaDoTempo?.iv[cardActive]?.cartao?.subConteudo
                    ?.conteudoMarkdown
                }
              />
            }
          </S.TextP>

          <S.ButtonImgBox>
            {data &&
              data?.linhaDoTempo?.iv[
                cardActive
              ]?.cartao?.subConteudo?.imagens.map((item, index) => (
                <S.ButtonImg
                  src={`${baseUrlImg}${item.imagem}`}
                  onClick={() => handleOpenModal(item.imagem[0])}
                  key={index}
                >
                  <ZoomInIcon />
                </S.ButtonImg>
              ))}
          </S.ButtonImgBox>
        </S.TimeLineBox>
      </S.Container>
    );
  }
};
