import React from "react";
import { HeaderStates } from "./HeaderStates";
import { PageNewState } from "./PageNewState";
import { StartupState } from "./StartupState";

export const Providers = ({ children }) => {
  return (
    <StartupState>
      <PageNewState>
          <HeaderStates>{children}</HeaderStates>
      </PageNewState>
    </StartupState>
  );
};
