import React, { useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { baseUrlImg, handleGetData } from '../../Services';
import { useStartupState } from '../../Providers/StartupState';
import * as S from './style';

export const ContactUs = ({ top = null }) => {
  const [data, setData] = useState(null);

  const navigate = useNavigate();

  const { token } = useStartupState();

  useEffect(() => {
    if (token) {
      handleGetData(token, 'api/content/copasul/faleconosco/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        });
    }
  }, [token]);

  return (
    <S.Container>
      <S.ImgBox top={top}>
        <img src={`${baseUrlImg}${data?.imagem?.iv[0]}`} alt="menina" />
      </S.ImgBox>
      <S.BannerBox>
        <h3>{data?.titulo?.iv}</h3>
        <p>{data?.texto?.iv}</p>
        <S.ButtonBackground onClick={() => navigate('/contato')}>
          {data?.tituloAparente?.iv} <ArrowForwardIcon />
        </S.ButtonBackground>
      </S.BannerBox>
    </S.Container>
  );
};
