/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import ContactlessIcon from '@mui/icons-material/Contactless';
import RadioIcon from '@mui/icons-material/Radio';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Loading } from '../../components/Loading';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../Services';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import * as S from './style';

export const RuralCopasul = () => {
  const [data, setData] = useState(null);

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/copasulrural/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.seo?.iv?.TituloSEO);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ position: 'absolute', color: '#fff' }}
        />
        <S.Container bgOurUnits={data?.banner?.iv?.imagemBackground[0]}>
          <S.DLBox>
            <img
              src={`${baseUrlImg}${data?.banner?.iv?.imagemSecundaria[0]}`}
              alt="Ciclo Copasul"
            />
            <S.DLLinkBox>
              <ReactMarkdown
                className="ReactMarkdown"
                children={data?.banner?.iv?.conteudoMarkdown}
              />
              <S.SpotifyButtonBox>
                <p>Sintonize:</p>
                <div>
                  <S.RadioBox>
                    <RadioIcon />
                    <p>
                      <ReactMarkdown
                        className="ReactMarkdown"
                        children={data?.conteudoAdicional?.iv}
                      />
                    </p>
                  </S.RadioBox>
                  <S.RadioBox>
                    <RadioIcon />
                    <p>
                      <ReactMarkdown
                        className="ReactMarkdown"
                        children={data?.markdownAdicional?.iv}
                      />
                    </p>
                  </S.RadioBox>
                </div>
              </S.SpotifyButtonBox>

              <S.SpotifyButtonBox>
                Ouça os últimos episódios:
                <Link
                  to={`${data?.banner?.iv?.botaoLink?.linkExterno}`}
                  target="_blank"
                >
                  <ContactlessIcon style={{ transform: 'rotate(-90deg)' }} />
                  {data?.banner?.iv?.botaoLink?.tituloLink}
                </Link>
              </S.SpotifyButtonBox>
            </S.DLLinkBox>
          </S.DLBox>
        </S.Container>
      </div>
    );
  }
};
