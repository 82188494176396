/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import * as S from './style';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Loading } from '../../components/Loading';
import { useStartupState } from '../../Providers/StartupState';
import { handleGetData, handlePostContact } from '../../Services';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Controller, useForm } from 'react-hook-form';

export const Contact = () => {
  const [data, setData] = useState(null);

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const containerStyle = {
    width: '70%',
    height: '400px',
  };
  const center = {
    lat: -23.04483975836086,
    lng: -54.19472783158419,
  };

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/contato/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.titulo?.iv);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  const onSubmit = (data) => {
    handlePostContact(
      'api/content/copasul/formulario/',
      {
        nome: { iv: data.name },
        email: { iv: data.email },
        telefone: { iv: data.fone },
        interesse: { iv: [data.areaInterest] },
        assunto: { iv: '' },
        mensagem: {
          iv: data.message,
        },
      },
      token,
    )
      .then((response) => response)
      .catch((e) => console.log('error =>', e));
    reset();
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        {console.log(loading)}
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ position: 'absolute' }}
        />
        <S.Container>
          <S.FormBox>
            <S.LeftFormBox>
              <S.HeaderFormBox>
                <h2>
                  <EmailOutlinedIcon /> {data?.tituloFormulario?.iv}
                </h2>
                <span>{data?.subtituloFormulario?.iv}</span>
              </S.HeaderFormBox>
              <S.Form onSubmit={handleSubmit(onSubmit)}>
                <div className="boxInput">
                  <input
                    type="text"
                    placeholder="Nome Completo*"
                    {...register('name', { required: true })}
                  />
                  {errors?.name && <p className="error">campo obrigatório</p>}
                </div>
                <div className="boxInput">
                  <input
                    type="text"
                    placeholder="E-mail*"
                    {...register('email', { required: true })}
                  />
                  {errors?.email && <p className="error">campo obrigatório</p>}
                </div>
                <div className="boxInput">
                  <input
                    type="text"
                    placeholder="Telefone*"
                    {...register('fone', { required: true })}
                  />
                  {errors?.fone && <p className="error">campo obrigatório</p>}
                </div>
                <div className="boxInput">
                  <Controller
                    rules={{ required: true }}
                    name="areaInterest"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <select
                        {...field}
                        labelId="areaInterest"
                        id="areaInterest"
                        label="Selecionar"
                      >
                        <option value="">Área de Interesse*</option>
                        <option value="Contas á Pagar">Contas á Pagar</option>
                        <option value="Agronômico">Agronômico</option>
                        <option value="Comercial">Comercial</option>
                        <option value="RH">RH</option>
                      </select>
                    )}
                  />
                  {errors?.areaInterest && (
                    <p className="error">campo obrigatório</p>
                  )}
                </div>
                <div className="textarea">
                  <textarea
                    type="text"
                    placeholder="Mensagem *"
                    {...register('message', { required: true })}
                  />
                  {errors?.message && (
                    <p className="error">campo obrigatório</p>
                  )}
                </div>
                <button type="submit">Enviar</button>
              </S.Form>
            </S.LeftFormBox>
            <S.RightFormBox>
              <h3>{data?.tituloLinks?.iv}</h3>
              {data &&
                data?.linksNavegacao?.iv.map((item,index) => (
                  <S.NavLinkCustom to={item?.link?.linkInterno} key={index}>
                    <ArrowForwardIcon />
                    {item?.link?.tituloLink}
                  </S.NavLinkCustom>
                ))}
              <S.InfoBox>
                <p>
                  <LocalPhoneIcon />
                  {data?.cartao?.iv?.informacoes[0]?.telefone
                    .toString()
                    .replace(/^(\d{2})\s*(\d{4,5})-?(\d{4})$/, '($1) $2-$3')}
                </p>
                <p>
                  <EmailIcon />
                  {data?.cartao?.iv?.informacoes[0]?.email}
                </p>
                <p>
                  <LocationOnIcon />
                  {data?.cartao?.iv?.informacoes[0]?.endereco}
                </p>
                <h4>{data?.cartao?.iv?.titulo}</h4>
                <ReactMarkdown
                  className="ReactMarkdown"
                  children={data?.cartao?.iv?.conteudoMarkdown}
                />
              </S.InfoBox>
            </S.RightFormBox>
          </S.FormBox>
          <S.MapsBox>
            <LoadScript googleMapsApiKey="AIzaSyBiWNeof64O1qi5A3cBphrAoXrC9exsuCI">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={18}
              >
                <>
                  <Marker position={center} />
                </>
              </GoogleMap>
            </LoadScript>
          </S.MapsBox>
        </S.Container>
      </div>
    );
  }
};
