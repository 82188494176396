/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useHeaderStates } from '../../Providers/HeaderStates';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { SocialMediaButtons } from '../../components/SocialMediaButtons';
import ShareIcon from '@mui/icons-material/Share';
import { getVidesChannel, handleGetData } from '../../Services';
import { format } from 'date-fns';
import ReactPlayer from 'react-player';
import { useForm } from 'react-hook-form';
import * as S from './style';
import { useStartupState } from '../../Providers/StartupState';
import { Loading } from '../../components/Loading';
import { Breadcrumbs } from '../../components/Breadcrumbs';

export const VideoGallery = () => {
  const [active] = useState(true);
  const [data, setData] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [srcModal, setSrcModal] = useState(false);
  const [openSocialMedia, setOpenSocialMedia] = useState(false);
  const [pages, setPages] = useState(10);
  const [videoTitle, setVideoTitle] = useState('');
  const [dataCms, setDataCms] = useState(null);

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();
  const { handleSubmit, register, watch } = useForm();

  useEffect(() => {
    getVidesChannel(pages, videoTitle)
      .then((response) => setData(response))
      .catch((e) => console.log('error =>', e));
  }, [pages, videoTitle]);

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/galeriavideos/')
        .then((response) => {
          setDataCms(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setDataCms(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(dataCms?.titulo?.iv);
    setBgHeader(dataCms?.imagemHeader?.iv[0]);
  });

  useEffect(() => {
    if (watch('title') === '') {
      setVideoTitle('');
    }
  }, [watch('title')]);

  const handleOnSubmit = (title) => {
    if (title.title !== '') {
      setVideoTitle(title.title);
    }
    return;
  };

  const handleVideo = (data, index, title) => {
    setActiveId(index);
    setOpenModal(true);
    setSrcModal({ data, title });
  };

  const handleLeadMore = () => {
    setPages(pages + 10);
  };

  const handleClosseModal = () => {
    setOpenModal(false);
    setOpenSocialMedia(false);
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ marginTop: '-1rem', position: 'relative' }}>
        {openModal && (
          <S.Modal onClick={handleClosseModal}>
            <S.ContentModal onClick={(e) => e.stopPropagation()}>
              <S.VideoBox title={srcModal.title}>
                <ReactPlayer
                  className="react-player"
                  url={`https://www.youtube.com/watch?v=${srcModal.data}`}
                  width="100%"
                  height="100%"
                  controls={false}
                  playing={false}
                />
              </S.VideoBox>
              <div>
                {openSocialMedia && (
                  <SocialMediaButtons
                    style={{
                      left: '5%',
                      marginTop: '2rem',
                    }}
                    open={() => {
                      setOpenSocialMedia(!openSocialMedia);
                    }}
                    url={`https://www.youtube.com/watch?v=${srcModal.data}`}
                  />
                )}
              </div>
              <button
                onClick={() => {
                  setOpenSocialMedia(!openSocialMedia);
                }}
              >
                <ShareIcon /> Compartilhar
              </button>
            </S.ContentModal>
          </S.Modal>
        )}

        {!openModal && (
          <Breadcrumbs
            content={dataCms?.breadScrumb?.iv}
            style={{
              zIndex: '1',
              position: 'absolute',
            }}
          />
        )}
        <S.Container bgOurUnits={dataCms?.imagemBackground?.iv[0]}>
          <S.FormSearch onSubmit={handleSubmit(handleOnSubmit)}>
            <S.SearchBox>
              <SearchIcon />
              <input
                type="text"
                placeholder="Faça sua busca aqui"
                {...register('title', {})}
              />
            </S.SearchBox>
            <S.LoadMoreButton type="submit" bgColor={false}>
              Buscar
            </S.LoadMoreButton>
          </S.FormSearch>
          <S.CardsBox>
            {data &&
              data.items.map((item, index) => (
                <S.ContentNewsBanner
                  key={item?.id?.videoId}
                  active={index === activeId && active}
                  onClick={() =>
                    handleVideo(item?.id?.videoId, index, item?.snippet?.title)
                  }
                >
                  <S.SpanDate>
                    {format(new Date(item?.snippet?.publishTime), 'dd MMM')}
                  </S.SpanDate>
                  <img
                    src={`${item?.snippet?.thumbnails?.high?.url}`}
                    alt="imagem de capa"
                  />
                  <S.CollectionsIconBox>
                    <SmartDisplayIcon />
                  </S.CollectionsIconBox>
                  <S.ContentNewsBannerTexts
                    active={index === activeId && active}
                  >
                    <h4 title={item?.snippet?.title}>{item?.snippet?.title}</h4>
                    <p>{item?.snippet?.description}</p>
                  </S.ContentNewsBannerTexts>
                </S.ContentNewsBanner>
              ))}
          </S.CardsBox>
          <S.LoadMoreButton onClick={handleLeadMore} bgColor={true}>
            Carregar mais
          </S.LoadMoreButton>
        </S.Container>
      </div>
    );
  }
};
