/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import * as S from './style';
import { useStartupState } from '../../Providers/StartupState';
import { Loading } from '../../components/Loading';
import { baseUrlImg, handleGetData } from '../../Services';
import { Breadcrumbs } from '../../components/Breadcrumbs';

export const Governance = () => {
  const [data, setData] = useState(null);
  const [screenWidth, setScreenWidth] = useState();
  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/governanca/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.seo?.iv?.TituloSEO);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        return setScreenWidth(data?.imagemPrincipalMobile?.iv[0]);
      } else if (window.innerWidth > 800) {
        return setScreenWidth(data?.imagemPrincipalWeb?.iv[0]);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ position: 'absolute' }}
        />
        <S.Container src={`${baseUrlImg}${data?.imagemFundo?.iv[0]}`}>
          <S.Title>{data?.titulo?.iv}</S.Title>
          <S.ImgGovernance src={`${baseUrlImg}${screenWidth}`} alt="" />
        </S.Container>
      </div>
    );
  }
};
