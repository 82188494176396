import React from "react";
import { useNavigate } from "react-router-dom";

const PageNewStateContext = React.createContext();

export const PageNewState = ({ children }) => {
  const [theNew, setTheNew] = React.useState(null);

  const navigate = useNavigate();

  const handlenBack = () => {
    setTheNew(null);
    navigate("/noticias");
  };

  return (
    <PageNewStateContext.Provider value={{ theNew, setTheNew, handlenBack }}>
      {children}
    </PageNewStateContext.Provider>
  );
};

export const usePageNewState = () => React.useContext(PageNewStateContext);
