import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ bgOurUnits }) => css`
    background: url(${bgOurUnits});
    padding: 5.625rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
    color: var(--black-color);
  `}
`;

export const DLBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  p {
    margin-bottom: 2rem;
  }
  img {
    width: 16rem;
    @media only screen and (min-width: 600px) {
      width: 20%;
    }
    @media only screen and (min-width: 854px) {
      width: 16rem;
    }
  }
`;

export const ListBox = styled.ul`
  margin-top: 4rem;
  li {
    margin-left: 2rem;
  }
`;

export const DLLinkBox = styled.div`
  width: 90%;
  max-width: 34.938rem;
  @media only screen and (min-width: 600px) {
    width: 70%;
  }
  @media only screen and (min-width: 854px) {
    width: 90%;
  }
  li,
  a {
    list-style-type: disc;
    text-decoration: underline;
    color: var(--black-color);
  }
`;

export const ImgLogo = styled.img`
  width: 90%;
  max-width: 34.938rem;
`;
