import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as S from './style';
import { NavLink } from 'react-router-dom';

export const OtherServices = ({ servicesLinks, marginTop }) => {
  return (
    <S.cardsBox marginTop={marginTop}>
      <h3>OUTROS SERVIÇOS</h3>
      <S.CardLinksBox>
        {servicesLinks &&
          servicesLinks.map((item) => (
            <S.CardLinks key={item?.cartao?.schemaId}>
              <h5>
                <ArrowForwardIcon />
                {item?.cartao?.titulo}
              </h5>
              <p>{item?.cartao?.subTitulo}</p>
              <NavLink to={item?.cartao?.link?.linkInterno}>
                {item?.cartao?.link?.tituloLink}
              </NavLink>
            </S.CardLinks>
          ))}
      </S.CardLinksBox>
    </S.cardsBox>
  );
};
