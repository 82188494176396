import styled from 'styled-components';
import Slider from 'react-slick';

export const SliderBox = styled(Slider)`
  .slick-next {
    margin-right: -25px;
    path {
      transform: translate(6px, 0);
    }
  }
  .slick-prev {
    z-index: 1000;
    margin-left: -25px;
  }

  .slick-prev,
  .slick-next {
    width: 3rem;
    height: 3rem;
    path {
      fill: var(--white-color);
    }
  }

`;
