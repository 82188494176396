import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ bgOurUnits }) => css`
    background: url(${bgOurUnits});
    padding: 5.625rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: var(--black-color);
  `}
`;

export const FormFilter = styled.form`
  display: flex;
  width: 90%;
  max-width: 44.688rem;
  gap: 1.5rem;
  margin: 0 auto 1.25rem;
  justify-content: center;
  @media only screen and (min-width: 800px) {
    justify-content: flex-start;
  }
  select {
    height: 2.5rem;
    background-color: var(--white-color);
    border: none;
    border-radius: 10px;
    padding: 10px;
  }
`;

export const Card = styled.div`
  display: flex;
  border-bottom: 2px solid var(--second-color);
  margin: 2.5rem auto;
  padding-bottom: 2.5rem;
  width: 90%;
  max-width: 44.688rem;
  justify-content: center;
  position: relative;
  @media only screen and (min-width: 800px) {
    justify-content: space-between;
    position: static;
  }
  h3 {
    align-items: center;
    display: flex;
    font-size: 1.25rem;
    margin-bottom: 11.5rem;
    @media only screen and (min-width: 800px) {
      margin-bottom: 1.25rem;
    }
  }
  p {
    font-weight: bold;
    padding-left: 5.375rem;

    span {
      font-weight: normal;
      margin-left: 0.5rem;
    }
  }

  img {
    position: absolute;
    margin-top: 5rem;
    @media only screen and (min-width: 800px) {
      position: static;
      margin-top: 0;
    }
  }
`;

export const SpanDate = styled.span`
  width: 3.375rem !important;
  height: 3.375rem;
  margin-right: 1rem;
  background-color: var(--second-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  color: var(--white-color);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
`;

export const ContentBox = styled.div`
  width: 100%;
`;

export const LinkHere = styled.p`
  margin-top: 2rem;
  font-weight: normal;
  a {
    text-decoration: underline;
  }
`;
