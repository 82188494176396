import styled, { css } from 'styled-components';
import { ButtonCustomBackground } from '../../components/ButtonCustom';

export const Container = styled.div`
  ${({ bgOurUnits }) => css`
    background: url(${bgOurUnits});
    padding: 5.625rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1rem;
    color: var(--white-color);
    @media only screen and (min-width: 500px) {
      padding: 5.625rem 5rem;
    }
  `}
`;

export const FormSearch = styled.form`
  width: 90%;
  margin-bottom: 4rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  input {
    height: 35px;
    width: 100%;
    border-radius: 0.625rem;
    border: 1px solid;
    border-color: var(--second-color);
    color: var(--second-color);
    font-size: 0.875rem;
    padding: 0 0.625rem;
    padding-left: 2.5rem;
    ::placeholder {
      color: var(--second-color);
      font-size: 0.625rem;
    }
  }
`;

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 90%;
  max-width: 35.813rem;
  svg {
    position: absolute;
    margin-left: 0.625rem;
    color: var(--second-color);
  }
`;

export const CardsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

export const ContentNewsBanner = styled.div`
  ${({ active }) => css`
    border-radius: 0.625rem;
    width: 90%;
    max-width: 22.5rem;
    height: 25.188rem;
    margin: 37px auto 5rem;
    box-shadow: 1px 2px 8px 5px rgba(0, 0, 0, 0.09);
    text-align: left;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    background-color: ${active ? 'var(--second-color)' : 'var(--white-color)'};
    cursor: pointer;

    img {
      width: 100%;
      border-radius: 0.625rem 0.625rem 0 0;
    }
  `}
`;

export const CollectionsIconBox = styled.div`
  position: relative;
  text-align: right;
  padding: 0 0.6rem 0.6rem;
  margin-top: -40px;
`;

export const SpanDate = styled.span`
  position: absolute;
  width: 3.375rem;
  height: 3.375rem;
  margin-top: -2.25rem;
  margin-left: 1rem;
  background-color: var(--second-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  color: var(--white-color);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
`;

export const ContentNewsBannerTexts = styled.div`
  ${({ active }) => css`
    padding: 0.75rem 1rem 1rem;
    color: ${active ? 'var(--white-color)' : ' var(--black-color)'};
    span {
      color: ${active ? 'var(--white-color)' : ' var(--ninth-color)'};
      font-size: 0.938rem;
    }

    h4 {
      font-size: 1.5rem;
      margin: 0.688rem 0 0.75rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p {
      font-size: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  `}
`;

export const LoadMoreButton = styled(ButtonCustomBackground)`
  ${({ bgColor }) => css`
    background-color: ${bgColor && 'var(--fourth-color)'};
    border-color: ${bgColor && 'var(--fourth-color)'};
    padding: 0.5rem 2.5rem;
    height: 37px;
  `}
`;
