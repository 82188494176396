import styled, { css } from 'styled-components';
import { ButtonCustomBackground } from '../../components/ButtonCustom';

export const Container = styled.div`
  background-image: url(${(props) => props.src});
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;

  padding-top: 5rem;
  padding-bottom: 6.5rem;
`;

export const BannerBox = styled.div`
  width: 47.813rem;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BannerContent = styled.div`
  color: var(--white-color);
  width: 17rem;
  margin-top: 8 rem;
  margin-left: 20rem;

  p {
    margin: 1rem 0;
    font-size: 1rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  position: absolute;
`;

export const ButtonBackground = styled(ButtonCustomBackground)`
  ${({ src }) => css`
    align-items: center;
    gap: 0.5rem;
    display: flex;
    background-color: ${src && 'var(--sixth-color)'};
    border-color: ${src && 'var(--sixth-color)'};
    color: ${src && 'var(--second-color)'};
    padding: 0.5rem 3rem;
    svg {
      font-size: 1rem;
    }
  `}
`;

export const BannerInputsBox = styled.div`
  color: var(--white-color);
  width: 100%;

  .ReactMarkdown {
    p {
      :nth-child(2){
      margin-top: 1rem;
    }
    }
  }
`;

export const Text = styled.p`
  margin: 0 auto 3.125rem;
  max-width: 68.25rem;
  width: 80%;
  font-size: 1rem;
`;

export const CardsInputsBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 6rem;
  width: 100%;
  margin-bottom: 5.625rem;
`;

export const Cards = styled.div`
  width: 13.125rem;
  text-align: center;

  img {
    width: 100%;
  }
  h5 {
    font-size: 1rem;
    margin: 0.8rem 0;

    ::after {
      content: '';
      display: block;
      height: 0.3rem;
      background-color: var(--sixth-color);
      border-radius: 0.5rem;
      margin-top: 0.3rem;
      width: 50%;
      margin: 0 auto;
    }
  }

  p {
    text-align: left;
    font-size: 0.875rem;
  }
`;

export const CardLinksBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  margin-top: -100px;
  gap: 1.25rem;
`;

export const CardLinks = styled.div`
  padding: 1rem;
  width: 21.75rem;
  height: 8.875rem;
  border-radius: 0.625rem;
  background: #ffffff;
  box-shadow: 10px 12px 14px rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  h5 {
    font-size: 1rem;
    color: var(--second-color);
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  svg {
    color: var(--seventh-color);
    font-size: 1rem;
    margin-right: 0.313rem;
  }
  p {
    font-size: 0.813rem;
  }
  button {
  }
`;
