/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ModalCustom } from '../../../../components/ModalCustom';
import { Loading } from '../../../../components/Loading';
import * as S from '../Photos/style';
import { baseUrlImg } from '../../../../Services';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const MdodalPhotos = (data) => {
  const { idPhoto, index } = useParams();
  const navigate = useNavigate();


  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const handleKeyDown = (e) => {
    if(e.keyCode === 37){
      navigate(`/fotos/${idPhoto}/fotos/${parseInt(index) === 0 ? data?.data?.fotosAlbum?.iv.length - 1 : parseInt(index) - 1}`)
    }
    else if(e.keyCode === 39){
      navigate(`/fotos/${idPhoto}/fotos/${parseInt(index) === (data?.data?.fotosAlbum?.iv.length - 1) ? 0 : parseInt(index) + 1}`)
    }
  }

  return (
    <ModalCustom
      open={true}
      handleClose={() => navigate(`/fotos/${idPhoto}`)}
    >
      {data ? (
        <S.ContentModal>
          <button
            onClick={() => handleKeyDown({ keyCode: 37 })}
            style={{ marginRight: '-4rem', zIndex: 9999, marginLeft: '1rem' }}
          >
            <ArrowBackIosIcon /> 
          </button>

          <img src={`${baseUrlImg}${data?.data?.fotosAlbum?.iv[index]}`} alt="" />

          <button
            onClick={() => handleKeyDown({ keyCode: 39 })}
            style={{ marginLeft: '-3rem' }}
          >
            <ArrowForwardIosIcon />
          </button>
        </S.ContentModal>
      ) : (
        <Loading />
      )}
    </ModalCustom>
  );
};


