import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Corn } from './components/Corn';
import { Soy } from './components/Soy';
import { Sorghum } from './components/Sorghum';
import { ContactUs } from '../../components/ContactUs';

export const GrainCommercial = () => {
  const [contactUsLess, setContactUsLess] = useState(true);

  return (
    <>
      <Routes>
        <Route path="/" element={<Corn />} end contactUsLess={contactUsLess} setContactUsLess={setContactUsLess}/>
        <Route path="/soy" element={<Soy />} contactUsLess={contactUsLess} setContactUsLess={setContactUsLess}/>
        <Route path="/sorghum" element={<Sorghum />} contactUsLess={contactUsLess} setContactUsLess={setContactUsLess}/>
      </Routes>
     {contactUsLess && <ContactUs top="-48px" />}
    </>
  );
};
