import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  color: var(--white-color);
`;

export const ImgLogo = styled.img`
    width: 90%;
    margin: 2rem 0;
    max-width: 31rem;
`;

export const ImgBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  img {
    margin: 0;
    width: 100%;
    @media only screen and (min-width: 800px) {
      width: 50%;
      border-radius: 15px;
    }
    @media only screen and (min-width: 1000px) {
      width: 33.33%;
    }
  }
`;
