import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterBox = styled.footer`
  font-size: 0.813rem !important;
  color: var(--first-color);
  background-color: var(--tenth-color);
  border: 1px solid transparent;
`;

export const TopPage = styled.a`
  background-color: var(--fourth-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: -25px auto;
  z-index: 100;
  position: relative;
  svg {
    height: 50px;
    width: 50px;
    color: var(--white-color);
  }
`;

export const FooterContetBox = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 66px 40px 0px;
  gap: 1rem;
  flex-wrap: wrap;
  position: relative;
`;

export const ColumnLinkBox = styled.div`
  gap: 1rem;
  box-sizing: border-box;
  overflox: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  @media only screen and (min-width: 533px) {
    height: 976px;
  }
  @media only screen and (min-width: 800px) {
    height: 543px;
  }
  @media only screen and (min-width: 1000px) {
    height: 349px;
  }
  @media only screen and (min-width: 1366px) {
    height: 250px;
  }
`;

export const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4rem;
  gap: 1rem;
  
  @media only screen and (min-width: 533px) {
    width: 50%;
    height: 100%;
  }
  @media only screen and (min-width: 800px) {
    width: 13.125rem;
  }

  img {
    width: 10.216rem;
  }
  p {
    svg {
      width: 1rem;
      margin-bottom: -7px;
      margin-right: 0.3rem;
    }
  }
`;

export const Description = styled.p`
  margin-top: 3rem;
`;

export const TitlesColumn = styled(Link)`
  color: var(--first-color);
  height: fit-content;
  width: 100%;
  display: grid;
  gap: 1rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  @media only screen and (min-width: 533px) {
    width: 50%;
    /* justify-content: center; */
  }
  @media only screen and (min-width: 800px) {
    width: 30%;
  }
  @media only screen and (min-width: 1000px) {
    width: 15%;
  }

  a {
    padding-left: 1rem;
    color: var(--second-color) !important;
  }
`;

export const SocialNetworksBox = styled.div`
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--second-color);
  margin-top: 75px;
  @media only screen and (min-width: 1000px) {
    margin-top: 100px;
  }
`;
export const SocialNetworks = styled.div`
  display: flex;
  border: 1px solid var(--second-color);
  padding: 0.625rem;
  border-radius: 0.625rem;
  justify-content: space-between;
  gap: 1.063rem;
  margin: 2.5rem auto;
  width: fit-content;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 1.111;
  }
`;

export const PartnersBox = styled.div`
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--second-color);
  margin-top: 75px;
  @media only screen and (min-width: 1000px) {
    margin-top: 100px;
  }
`;
export const Partners = styled.div`
  display: flex;
  padding: 0.625rem;
  border-radius: 0.625rem;
  justify-content: space-between;
  margin: 2.5rem auto;
  width: fit-content;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 200px;
    margin: 0px 10px 0px 10px;
  }
`;

export const LogoFooterBox = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--second-color);
  @media only screen and (min-width: 533px) {
    justify-content: flex-end;
    padding-right: 50px;
  }
  @media only screen and (min-width: 800px) {
    padding-right: 107px;
  }
  img {
    width: 80%;
    margin-bottom: -7%;
    @media only screen and (min-width: 533px) {
      width: 60%;
      margin-bottom: -5%;
    }
    @media only screen and (min-width: 1000px) {
      width: 30rem;
      margin-bottom: -43px;
    }
  }
`;

export const copyright = styled.p`
  text-align: center;
  padding: 25px;

`;
