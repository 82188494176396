/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Loading } from '../../components/Loading';
import { baseUrlImg, handleGetData } from '../../Services';
import { useStartupState } from '../../Providers/StartupState';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import * as S from './style';

export const Interviwes = () => {
  const [search, setSearch] = useState('');
  const [data, setData] = useState(null);
  const [entrevistas, setEntrevistas] = useState([]);
  const [top, setTop] = useState(10);

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();
  const navigate = useNavigate();
  const { handleSubmit, register, watch } = useForm();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, `api/content/copasul/entrevistas/`)
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(
        token,
        `api/content/copasul/entrevista/?$top=${top}&?$search=entrevista`,
      )
        .then((response) => {
          setEntrevistas(response?.data?.items);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setEntrevistas([]);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, top]);

  useEffect(() => {
    setTitle(data?.seo?.iv?.TituloSEO);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  useEffect(() => {
    if (watch('title') === '') {
      setSearch('');
    }
  }, [watch('title')]);

  const handleOnSubmit = (title) => {
    if (title.title !== '') {
      setSearch(title.title);
    }
    return;
  };

  const handlePageNew = (data) => {
    navigate(`/paginaEntrevista/${data}`);
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ position: 'absolute' }}
        />
        <S.Container bgOurUnits={data?.imagemBackground?.iv[0]}>
          <S.FormSearch onSubmit={handleSubmit(handleOnSubmit)}>
            <S.SearchBox>
              <SearchIcon />
              <input
                type="text"
                placeholder="Faça sua busca aqui"
                {...register('title', {})}
              />
            </S.SearchBox>
            <S.LoadMoreButton bgColor={false}>Buscar</S.LoadMoreButton>
          </S.FormSearch>
          <S.CardsBox>
            {entrevistas &&
              entrevistas
                .filter((item) => {
                  if (!search) return true;
                  const title = item?.data?.titulo?.iv.toLowerCase();

                  return title.includes(search.toLowerCase());
                })
                .map((item) => (
                  <S.ContentNewsBanner
                    key={item?.schemaId}
                    onClick={() => handlePageNew(item?.data?.idUrl?.iv)}
                  >
                    <S.SpanDate>
                      {format(
                        new Date(item?.data?.dataEntrevista?.iv),
                        'dd MMM',
                        {
                          locale: ptBR,
                        },
                      )}
                    </S.SpanDate>
                    <img
                      src={`${baseUrlImg}${item?.data?.imagens?.iv[0]}`}
                      alt="Imagem da Notícia"
                    />

                    <S.ContentNewsBannerTexts>
                      <span>{item?.data?.autorEntrevista?.iv}</span>
                      <h4 title={item?.data?.titulo?.iv}>
                        {item?.data?.titulo?.iv}
                      </h4>
                      <p>{item?.data?.subTitulo?.iv}</p>
                    </S.ContentNewsBannerTexts>
                  </S.ContentNewsBanner>
                ))}
          </S.CardsBox>
          <S.LoadMoreButton bgColor={true} onClick={() => setTop(top + 10)}>
            Carregar mais
          </S.LoadMoreButton>
        </S.Container>
      </div>
    );
  }
};
