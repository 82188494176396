/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Menu } from '../Menu';
import { LatestNews } from '../../../../components/LatestNews';
import { LinkServices } from '../../../../components/LinkServices';
import { baseUrlImg, handleGetData } from '../../../../Services';
import { useStartupState } from '../../../../Providers/StartupState';
import { useHeaderStates } from '../../../../Providers/HeaderStates';
import { Loading } from '../../../../components/Loading';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import * as S from './style';

export const Sorghum = () => {
  const [data, setData] = useState(null);
  const [priceImg, setPriceImg] = useState();
  const [BgImg, setBgImg] = useState();

  const { token, setError, loading, setLoading } = useStartupState();
  const { setTitle, setBgHeader } = useHeaderStates();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/comercialdesorgo/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setBgImg(data?.imagemFundoMobile?.iv[0]);
        return setPriceImg(data?.imagemCotacao?.iv[0]);
      } else if (window.innerWidth >= 800 && window.innerWidth <= 1100) {
        setBgImg(data?.imagemFundoWeb?.iv[0]);
        return setPriceImg(data?.imagemCotacao?.iv[1]);
      } else if (window.innerWidth > 1100) {
        setBgImg(data?.imagemFundoWeb?.iv[0]);
        setPriceImg(data?.imagemCotacao?.iv[1]);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    setTitle(data?.seo?.iv?.TituloSEO);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  if (loading) {
    return <Loading />;
  }
  if (data) {
    return (
      <S.Container src={`${baseUrlImg}${BgImg}`}>
        <section>
          <Menu readcrumbs={data?.breadScrumb?.iv} />
          <S.ListBox>
            <ReactMarkdown children={data?.conteudoMarkdown?.iv} />
          </S.ListBox>
          <S.QuotationBox>
            <S.ImgBox>
              <img src={`${baseUrlImg}${priceImg}`} alt="" style={{ width: '100%' }} />
            </S.ImgBox>
            <LinkServices linksServices={data?.links?.iv} BGColor={'#251206'} />
          </S.QuotationBox>
        </section>
        {data && <LatestNews BGColor={'#251206'} />}
      </S.Container>
    );
  }
};
