/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { useForm } from 'react-hook-form';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData, handlePostContact } from '../../Services';
import { Loading } from '../../components/Loading';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { ReactComponent as ImprensaIcon } from '../../assets/imprensa_icon.svg';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import * as S from './style';

export const PressRoom = () => {
  const [data, setData] = useState(null);

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/saladeimprensa/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.titulo?.iv);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  const onSubmit = (data) => {
    handlePostContact(
      'api/content/copasul/formulario/',
      {
        nome: { iv: data.name },
        email: { iv: data.email },
        telefone: { iv: data.fone },
        assunto: { iv: data.subject },
        mensagem: {
          iv: data.message,
        },
      },
      token,
    )
      .then((response) => response)
      .catch((e) => console.log('error =>', e));
    reset();
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ position: 'absolute', color: '#fff' }}
        />
        <S.Container src={`${baseUrlImg}${data?.imagemBackground?.iv[0]}`}>
          <S.LeftFormBox>
            <S.HeaderFormBox>
              <h2>
                <ImprensaIcon /> {data?.tituloFormulario?.iv}
              </h2>
              <span>{data?.subtitulo?.iv}</span>
            </S.HeaderFormBox>
            <S.Form onSubmit={handleSubmit(onSubmit)}>
              <div className="boxInput">
                <input
                  type="text"
                  placeholder="Nome Completo*"
                  {...register('name', { required: true })}
                />
                {errors?.name && <p className="error">campo obrigatório</p>}
              </div>
              <div className="boxInput">
                <input
                  type="text"
                  placeholder="E-mail*"
                  {...register('email', { required: true })}
                />
                {errors?.email && <p className="error">campo obrigatório</p>}
              </div>
              <div className="boxInput">
                <input
                  type="text"
                  placeholder="Telefone*"
                  {...register('fone', { required: true })}
                />
                {errors?.fone && <p className="error">campo obrigatório</p>}
              </div>
              <div className="boxInput">
                <input
                  type="text"
                  placeholder="Assunto*"
                  {...register('subject', { required: true })}
                />
                {errors?.subject && <p className="error">campo obrigatório</p>}
              </div>
              <div className="textarea">
                <textarea
                  type="text"
                  placeholder="Mensagem *"
                  {...register('message', { required: true })}
                />
                {errors?.message && <p className="error">campo obrigatório</p>}
              </div>
              <button type="submit">Enviar</button>
            </S.Form>
          </S.LeftFormBox>
          <S.InfoBox>
            <p>
              <LocalPhoneIcon />
              {data?.cartao?.iv?.informacoes[0]?.telefone
                .toString()
                .replace(/^(\d{2})\s*(\d{4,5})-?(\d{4})$/, '($1) $2-$3')}
            </p>
            <p>
              <EmailIcon />
              {data?.cartao?.iv?.informacoes[0]?.email}
            </p>
            <p>
              <LocationOnIcon />
              {data?.cartao?.iv?.informacoes[0]?.endereco}
            </p>
            <h4>{data?.cartao?.iv?.titulo}</h4>
            <ReactMarkdown
              className="ReactMarkdown"
              children={data?.cartao?.iv?.conteudoMarkdown}
            />
          </S.InfoBox>
        </S.Container>
      </div>
    );
  }
};
