/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { ReactComponent as PdfIcon } from '../../assets/pdf_icon.svg';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../Services';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Loading } from '../../components/Loading';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import * as S from './style';

export const ReportsDocuments = () => {
  const [data, setData] = useState(null);

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/relatorios/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.titulo?.iv);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ position: 'absolute' }}
        />
        <S.Container bgOurUnits={data?.imagemFundo?.iv[0]}>
          <S.DLBox>
            <img
              src={`${baseUrlImg}${data?.imagemRelatorio?.iv}`}
              alt="relatorios/documentos"
            />
            <S.DLLinkBox>
              <ReactMarkdown
                className="ReactMarkdown"
                children={data?.conteudoMarkdownPrimario?.iv}
              />
              <a
                href={data?.relatorio?.iv?.linkExterno}
                target="_blank"
              >
                <PdfIcon />
                {data?.relatorio?.iv?.tituloLink}
              </a>
              <S.ListBox>
                {data?.relatoriosAnteriores?.iv?.relatorioAnterior?.documento[0] && (
                  <span> Anos anteriores:</span>
                )}
                {data &&
                  data?.relatoriosAnteriores?.iv.map((item) => (
                    <li key={item?.relatorioAnterior?.schemaId}>
                      <a
                        href={item?.relatorioAnterior?.linkExterno}
                        download
                        target="_blank"
                      >
                        {item?.relatorioAnterior?.tituloLink}
                      </a>
                    </li>
                  ))}
              </S.ListBox>
            </S.DLLinkBox>
          </S.DLBox>
          <S.ImgLogo
            src={`${baseUrlImg}${data?.imagemPrincipal?.iv[0]}`}
            alt="Logo de 45 anos da Copasul"
          />
        </S.Container>
      </div>
    );
  }
};
