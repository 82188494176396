import styled, { css } from 'styled-components';
import {
  ButtonCustomBackground,
  ButtonCustomTransparent,
} from '../../ButtonCustom';

export const HeaderBox = styled.header`
  ${({ src }) => css`
    background: ${src ? `url(${src}) no-repeat` : 'transparent'};
    background-size: cover;
  `}
`;

export const FirstBox = styled.div`
  ${({ src }) => css`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem 3.125rem;

    select {
      border: none;
      color: ${src ? ' var(--white-color)' : ' var(--first-color)'};
      font-size: 0.875rem;
      margin-left: 26px;
      font-weight: bold;
    }
  `}
`;

export const SearchBox = styled.form`
  ${({ src }) => css`
    display: flex;
    align-items: center;
    position: relative;
    svg {
      position: absolute;
      margin-left: 2rem;
      z-index: 1;
      color: ${src ? 'var(--white-color);' : 'var(--octave-color);'};
    }
    select {
      height: 30px;
      width: 11rem;
      border-radius: 0.625rem;
      border: 1px solid;
      border-color: ${src ? 'var(--white-color);' : 'var(--octave-color);'};
      color: ${src ? 'var(--white-color);' : 'var(--octave-color);'};
      font-size: 0.875rem;
      padding: 0 0.625rem;
      padding-left: 2.5rem;
      option {
        color: var(--octave-color);
        margin: 0.5rem auto;
      }
    }
  `}
`;

export const LogoImg = styled.img`
  width: 5.75rem;
`;
export const ButtonsBox = styled.div`
  align-items: center;
  display: flex;
  gap: 0.625rem;
`;

export const SecondBox = styled.div`
  ${({ borderBottom, src }) => css`
    display: flex;
    border-top: 1px solid var(--second-color);
    ${borderBottom && 'border-bottom: 1px solid var( --second-color);'}
    justify-content: center;
    padding: 1rem 3.125rem;
    border-color: ${src ? 'var(--white-color)' : 'var(--second-color)'};
  `}
`;

export const SecondNav = styled.nav`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
  @media only screen and (min-width: 1200px) {
    justify-content: space-between;
    gap: initial;
  }
`;

export const MenuButton = styled.button`
  ${({ src }) => css`
    color: ${src ? 'var(--white-color)' : 'var(--first-color)'};
    display: flex;
    align-items: center;
    font-size: 0.875rem !important;
    height: 100%;
    svg {
      color: ${src ? 'var(--sixth-color)' : 'var(--second-color)'};
      margin-left: -2px;
    }
  `}
`;

export const SubMenu = styled.div`
  ${({ src }) => css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 1rem;
    border-radius: 0.5rem;
    white-space: nowrap;
    z-index: 100;
    a {
      color: var(--second-color);
      :visited {
        color: var(--second-color);
      }
    }
  `}
`;

export const TitlePage = styled.h1`
  ${({ paddingHidden }) => css`
    ${paddingHidden && 'padding: 1.25rem 2rem;'}
    text-align: center;
    color: var(--white-color);
    font-size: 2rem;
  `}
`;

export const ButtonBackground = styled(ButtonCustomBackground)`
  ${({ src }) => css`
    background-color: ${src && 'var(--sixth-color)'};
    border-color: ${src && 'var(--sixth-color)'};
    color: ${src && 'var(--second-color)'};
  `}
`;

export const ButtonTransparent = styled(ButtonCustomTransparent)`
  ${({ src }) => css`
    border-color: ${src && 'var(--white-color)'};
    color: ${src && 'var(--white-color)'};
  `}
`;

export const BackgroundSubMenu = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 10;
`;
