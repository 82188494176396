import React from 'react';
import { NavLink } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as S from './style';

export const LinkServices = ({ linksServices, BGColor }) => {
  return (
    <S.LinkServicesBox BGColor={BGColor}>
      <S.LinkServices>
        <h3>LINKS DE SERVIÇOS</h3>
        <div >
          {linksServices &&
            linksServices.map((item) => (
              <NavLink to={item?.link?.linkInterno} key={item?.link?.schemaId}>
                <ArrowForwardIcon />
                {item?.link?.tituloLink}
              </NavLink>
            ))}
        </div>
      </S.LinkServices>
    </S.LinkServicesBox>
  );
};
