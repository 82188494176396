/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { RotatingBanner } from '../../components/RotatingBanner';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../Services';
import { Loading } from '../../components/Loading';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import * as S from './style';

export const SrSakae = () => {
  const [data, setData] = useState(null);
  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/memorias/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.titulo?.iv);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ color: '#fff', position: 'absolute' }}
        />
        <S.Container src={`${baseUrlImg}${data?.imagemBackground?.iv[0]}`}>
          <S.ImgFirst>
            <S.ImgBox>
              <S.ImgBoxContent>
                <h2>{data?.bannerTopo?.iv?.titulo}</h2>
                <ReactMarkdown children={data?.bannerTopo?.iv?.subtitulo} />
              </S.ImgBoxContent>
              <img src={`${baseUrlImg}${data?.bannerTopo?.iv?.imagemBackground}`} alt="" />
            </S.ImgBox>
            <ReactMarkdown children={data?.bannerTopo?.iv?.conteudoMarkdown} />
          </S.ImgFirst>
          <S.ImgSecond>
            <S.backgroundImg
              src={`${baseUrlImg}${data?.bannerSecundario?.iv?.imagemBackground[0]}`}
              alt="background sr. sakae"
            />
            <S.ImgSecondText>
              <h2>{data?.bannerSecundario?.iv?.titulo}</h2>
              <ReactMarkdown children={data?.bannerSecundario?.iv?.subtitulo} />
            </S.ImgSecondText>
            <S.WritingImg
              src={`${baseUrlImg}${data?.bannerSecundario?.iv?.imagemSecundaria[0]}`}
              alt=""
            />
          </S.ImgSecond>
          <S.RotatingBannerBox>
            <h3>{data?.subtitulo?.iv}</h3>
            <RotatingBanner slidesToShow={1} slidesToScroll={1}>
              {data?.imagens?.iv.map((item, index) => (
                <div style={{ padding: '1rem' }} key={index}>
                  <S.RotatingBannercontent>
                    <img key={index} src={`${baseUrlImg}${item.imagem}`} alt="" />
                    <p>{item.titulo}</p>
                    <p>{item.autor}</p>
                  </S.RotatingBannercontent>
                </div>
              ))}
            </RotatingBanner>
          </S.RotatingBannerBox>
        </S.Container>
      </div>
    );
  }
};
