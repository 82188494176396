import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ bgOurUnits }) => css`
    background: url(${bgOurUnits});
    padding: 5.625rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
    color: var(--black-color);
  `}

  .ReactMarkdown {
    padding: 0 10%;
    p {
      margin-bottom: 1.5rem;
      width: 90%;
      max-width: 44.625rem;
      :nth-last-child(2) {
        margin-bottom: 3rem;
      }
    }
  }
  img {
    width: 90%;
    max-width: 37.396rem;
  }
`;
