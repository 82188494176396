/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ShareIcon from '@mui/icons-material/Share';
import { SocialMediaButtons } from '../../../../components/SocialMediaButtons';
import { useNavigate, useParams } from 'react-router-dom';
import { useHeaderStates } from '../../../../Providers/HeaderStates';
import { useStartupState } from '../../../../Providers/StartupState';
import { handleGetData, handleGetToken } from '../../../../Services';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Breadcrumbs } from '../../../../components/Breadcrumbs';
import HtmlParser from 'html-react-parser';
import { Loading } from '../../../../components/Loading';
import * as S from './style';

export const PageInterview = () => {
  const [openSocialMedia, setOpenSocialMedia] = useState(false);
  const [data, setData] = useState(null);
  const [token, setToken] = useState('');

  const { setTitle, setBgHeader } = useHeaderStates();
  const { setError, loading, setLoading } = useStartupState();
  const navigate = useNavigate();

  const { idEntrevista } = useParams();

  const handleCloseSocialMedia = () => {
    if (openSocialMedia) {
      setOpenSocialMedia(false);
    }
  };

  useEffect(() => {
    handleGetToken()
      .then((response) => {
        setToken(response?.data?.access_token);
        if (response?.status !== 200) {
          throw new Error(response.message);
        }
      })
      .catch((e) => {
        console.log(e.message);
        setError(e.message);
      });
  }, []);

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(
        token,
        `api/content/copasul/entrevista/?&$filter=data/idUrl/iv eq '${idEntrevista}'`,
      )
        .then((response) => {
          if (response?.data?.items[0]?.data) {
            setData(response?.data?.items[0]?.data);
          } else {
            navigate('/entrevistas');
          }
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle('Notícia');
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.Breadcrumbs?.iv}
          style={{ position: 'absolute', zIndex: '1' }}
        />
        <S.Container
          bgOurUnits={data?.imagemBackground?.iv[0]}
          onClick={handleCloseSocialMedia}
        >
          <S.ContentBox>
            <S.HeaderPage>
              <span>
                {data?.dataEntrevista && (
                  <span>
                    {format(
                      parseISO(data?.dataEntrevista?.iv),
                      'dd, MMM yyyy',
                      {
                        locale: ptBR,
                      },
                    )}
                  </span>
                )}
              </span>
              <span>ENTREVISTA</span>
            </S.HeaderPage>
            {data?.conteudoHtml && HtmlParser(data?.conteudoHtml?.iv)}
            <S.FooterPage>
              {openSocialMedia && (
                <SocialMediaButtons
                  open={() => setOpenSocialMedia(!openSocialMedia)}
                  url={`http://www.copasul.coop.br/paginaEntrevista/${idEntrevista}`}
                />
              )}
              <button
                onClick={() => setOpenSocialMedia(!openSocialMedia)}
                style={{ gap: '0.5rem' }}
              >
                <ShareIcon /> Compartilhar
              </button>
              <button onClick={() => navigate('/entrevistas')}>
                <ArrowBackIosIcon /> Voltar
              </button>
            </S.FooterPage>
          </S.ContentBox>
        </S.Container>
      </div>
    );
  }
};
