/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { useStartupState } from '../../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../../Services';
import { useForm } from 'react-hook-form';
import logoCopasulDefault from '../../../assets/Logo_copasul.svg';
import logoCopasulWhiteDefault from '../../../assets/Logo_copasul_white.svg';
import * as S from './style';

export const MenuDesktop = ({
  title,
  bgHeader,
  menu,
  setMenu,
  optionsMenu,
}) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [logoCopasulWhite, setLogoCopasulWhite] = useState('');
  const [logoCopasul, setLogoCopasul] = useState('');

  const { token, setError, setLoading } = useStartupState();
  const navigate = useNavigate();
  const { register, reset } = useForm();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/layoutheader/')
        .then((response) => {
          setData(response?.data?.items[0]?.data?.header?.iv);

          response?.data?.items[0]?.data?.header?.iv?.logoBranca[0]
            ? setLogoCopasulWhite(
                response?.data?.items[0]?.data?.header?.iv?.logoBranca[0].toString(),
              )
            : setLogoCopasulWhite(logoCopasulWhiteDefault);

          response?.data?.items[0]?.data?.header?.iv?.logoVerde[0]
            ? setLogoCopasul(
                response?.data?.items[0]?.data?.header?.iv?.logoVerde[0].toString(),
              )
            : setLogoCopasul(logoCopasulDefault);

          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    menu.forEach((item, i) => {
      item.status = false;
      setOpen(false);
    });
  }, [navigate]);

  const handleMenu = (index, route) => {
    if(route != null && route.indexOf('http') !== -1){
      window.open(route, '_blank')
      return;
    }

    navigate(route);
    let updateMenu = [...menu];

    updateMenu.forEach((item, i) => {
      item.status = i === index ? !item.status : false;
    });

    setMenu(updateMenu);
    setOpen(true);
  };

  const handleCloseMenu = () => {
    let updateMenu = [...menu];

    updateMenu.forEach((item, i) => {
      item.status = false;
      setOpen(false);
    });

    setMenu(updateMenu);
  };

  const handleSelectChange = (e) => {
    navigate(e.target.value);
    reset({ selectField: '' });
  };

  return (
    <S.HeaderBox src={`${baseUrlImg}${bgHeader}`}>
      {open && <S.BackgroundSubMenu onClick={handleCloseMenu} />}

      <S.FirstBox src={bgHeader}>
        <button>
          <S.LogoImg
            src={
              bgHeader
                ? `${baseUrlImg}${logoCopasulWhite}`
                : `${baseUrlImg}${logoCopasul}`
            }
            alt="Logo copasul"
            onClick={() => navigate('/')}
          />
        </button>
        <S.ButtonsBox>
          {data?.botoes[0]?.linkBotao?.linkExterno && (
            <S.ButtonTransparent
              onClick={() => {
                window.open(
                  `${data?.botoes[0]?.linkBotao?.linkExterno}`,
                  '_blank',
                );
              }}
              src={bgHeader}
            >
              {data?.botoes[0]?.linkBotao?.tituloLink}
            </S.ButtonTransparent>
          )}
          {data?.botoes[1]?.linkBotao?.linkExterno && (
            <S.ButtonBackground
              onClick={() => {
                window.open(
                  `${data?.botoes[1]?.linkBotao?.linkExterno}`,
                  '_blank',
                );
              }}
              src={bgHeader}
            >
              {data?.botoes[1]?.linkBotao?.tituloLink}
            </S.ButtonBackground>
          )}

          <S.SearchBox src={bgHeader}>
            <SearchIcon />
            <select
              id="selectField"
              {...register('selectField')}
              onChange={handleSelectChange}
            >
              <option value="" disabled>
                Faça sua busca aqui
              </option>
              {optionsMenu.map((item, index) => (
                <option key={index} value={item?.route}>
                  {item?.title}
                </option>
              ))}
            </select>
          </S.SearchBox>
          {/* <select>
            <option value="">pt</option>
          </select> */}
        </S.ButtonsBox>
      </S.FirstBox>
      <S.SecondBox borderBottom={title} src={bgHeader}>
        <S.SecondNav>
          {menu.map((item, index) => (
            <div key={index} style={{ position: 'relative' }}>
              <S.MenuButton
                src={bgHeader}
                onClick={() => handleMenu(index, item?.menu?.rota)}
              >
                <p>{item?.menu?.tituloMenu}</p>
                {item?.menu?.subMenu?.length > 0 && <ArrowDropDownIcon />}
              </S.MenuButton>
              {item?.menu?.subMenu?.length > 0 && item.status && (
                <S.SubMenu src={bgHeader}>
                  {item?.menu?.subMenu?.map((subItem, index) => (
                    <NavLink key={index} to={subItem?.rotaSubmenu}>
                      {subItem?.tituloSubmenu}
                    </NavLink>
                  ))}
                </S.SubMenu>
              )}
            </div>
          ))}
        </S.SecondNav>
      </S.SecondBox>
      <S.TitlePage paddingHidden={title}>{title}</S.TitlePage>
    </S.HeaderBox>
  );
};
