/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../Services';
import { Loading } from '../../components/Loading';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import * as S from './style';

export const WorkWithUs = () => {
  const [data, setData] = useState(null);

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/trabalheconosco/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.titulo?.iv);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ color: '#fff', position: 'absolute' }}
        />
        <S.Container src={`${baseUrlImg}${data?.banner?.iv?.imagemBackground[0]}`}>
          <img
            className="icon"
            src={`${baseUrlImg}${data?.banner?.iv?.imagemSecundaria[0]}`}
            alt=""
          />
          <h2>{data?.banner?.iv?.titulo}</h2>
          <p>{data?.banner?.iv?.subtitulo}</p>
          <S.ButtonBox>
            <S.NavLinkCustom
              to={data?.banner?.iv?.botaoLink?.linkExterno}
              target="_blank"
            >
              {data?.banner?.iv?.botaoLink?.tituloLink}
            </S.NavLinkCustom>
            <S.NavLinkCustom
              to={data?.banner?.iv?.botaoSecundario?.linkExterno}
              target="_blank"
            >
              {data?.banner?.iv?.botaoSecundario?.tituloLink}
            </S.NavLinkCustom>
          </S.ButtonBox>
        </S.Container>
      </div>
    );
  }
};
