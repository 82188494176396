import React, { createContext, useContext, useEffect, useState } from 'react';
import { handleGetToken } from '../../Services';
const StartupStateContext = createContext();

export const StartupState = ({ children }) => {
  const [token, setToken] = useState('');
  const [data, setData] = useState('');
  const [logoHeader, setLogoHeader] = useState('');
  const [logoHeaderContracted, setLogoHeaderContracted] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetToken()
      .then((response) => {
        setToken(response?.data?.access_token);
        if (response?.status !== 200) {
          throw new Error(response.message);
        }
      })
      .catch((e) => {
        console.log(e.message);
        setError(e.message);
      });

  }, []);

  return (
    <StartupStateContext.Provider
      value={{
        token,
        setToken,
        data,
        setData,
        logoHeader,
        setLogoHeader,
        logoHeaderContracted,
        setLogoHeaderContracted,
        error,
        setError,
        loading,
        setLoading,
      }}
    >
      {children}
    </StartupStateContext.Provider>
  );
};

export const useStartupState = () => useContext(StartupStateContext);
