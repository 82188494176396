/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { MenuDesktop } from './MenuDesktop';
import { MenuMobile } from './MenuMobile';

export const Header = () => {
  const [mobileMenu, SetMobileMenu] = useState(false);
  const [media] = useState('(max-width: 800px)');
  const [optionsMenu, setOptionsMenu] = useState([]);

  const { matches } = window.matchMedia(media);
  const {
    title,
    bgHeader,
    menu,
    setMenu,
  } = useHeaderStates();

  useEffect(() => {
    let optionsArray = [];

    menu.map((item) => {
      item?.menu?.rota
        ? optionsArray.push({
            title: item?.menu?.tituloMenu,
            route: item?.menu?.rota,
          })
        : item?.menu?.subMenu?.map((subItem) => {
            optionsArray.push({
              title: subItem?.tituloSubmenu,
              route: subItem?.rotaSubmenu,
            });
          });
    });

    setOptionsMenu(optionsArray);
  }, [menu]);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(media);
    const changeMatch = () => {
      SetMobileMenu(mediaQueryList.matches);
    };

    changeMatch();

    mediaQueryList.addEventListener('change', changeMatch);
    return () => {
      mediaQueryList.removeEventListener('change', changeMatch);
    };
  }, [media]);

  useEffect(() => {
    SetMobileMenu(matches);
  }, [matches]);

  return (
    <>
      {mobileMenu ? (
        <MenuMobile
          title={title}
          bgHeader={bgHeader}
          menu={menu}
          setMenu={setMenu}
          optionsMenu={optionsMenu}
        />
      ) : (
        <MenuDesktop
          title={title}
          bgHeader={bgHeader}
          menu={menu}
          setMenu={setMenu}
          optionsMenu={optionsMenu}
        />
      )}
    </>
  );
};
