import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 1rem 1rem 0;
  position: relative;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  text-align: center;
  width: 100vw;
  p {
    width: 90%;
  }
  @media only screen and (min-width: 600px) {
    text-align: left;
    padding: 1rem 0 0 8.75rem;
  }
`;

export const Breadcrumbs = ({ content, ...props }) => {
  return (
    <Container {...props}>
      <p>{content}</p>
    </Container>
  );
};
