import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  .eqKzXE,
  .slick-dots {
    margin-bottom: -25px !important;
  }
`;

export const HistoryBox = styled.div`
  img {
    width: 100%;
  }
`;

export const BannerHistoryBox = styled.div`
  font-size: 1.5rem;
  color: var(--white-color);
  background-color: #030b0b;
  text-align: center;
  padding: 0 2.5rem 1rem;
  margin-top: -20%;
  @media only screen and (min-width: 800px) {
    font-size: 1rem;
    margin-top: -10%;
  }
  @media only screen and (min-width: 1000px) {
    padding: 2rem 6rem;
  }
  @media only screen and (min-width: 1366px) {
    padding: 2rem 14.563rem;
    background-color: transparent;
    margin-top: -200px;
  }
  p {
    width: 100%;
    @media only screen and (min-width: 1366px) {
      width: 56.25rem;
      margin: 0 auto;
    }
  }
`;

export const TimeLineBox = styled.div`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  padding: 3.313rem 0;
  margin-top: -21px;
  h2 {
    padding: 2.813rem 0;
    text-align: center;

    ::after {
      content: '';
      display: block;
      height: 0.3rem;
      background-color: var(--seventh-color);
      border-radius: 0.5rem;
      margin-top: 0.3rem;
      width: 5rem;
      margin: 0 auto;
    }
  }
`;

export const CardsBox = styled.div`
  margin: 0 auto;
  @media only screen and (min-width: 600px) {
    width: 90%;
  }
  @media only screen and (min-width: 1366px) {
    width: 67.813rem;
  }
  .slick-next,
  .slick-prev {
    z-index: 1;
    path {
      fill: var(--second-color);
      display: none;
      @media only screen and (min-width: 1366px) {
        display: block;
      }
    }
  }
  .slick-dots {
    width: 100% !important;
  }
`;

export const Cards = styled.div`
  box-sizing: border-box;
  padding: 0 5rem;
  display: flex !important;
  justify-content: center;
  @media only screen and (min-width: 500px) {
    padding: 0;
  }

  .active {
    background-color: var(--second-color);
    color: var(--white-color);
    span {
      ::after {
        content: '';
        display: block;
        height: 0.3rem;
        background-color: var(--seventh-color);
        border-radius: 0.5rem;
        width: 5rem;
        margin: 0.5rem auto 0;
      }
    }
  }
`;

export const CardsContent = styled.div`
  border-radius: 0.625rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 12rem;
  height: 6rem;
  background-color: var(--fifth-color);
  box-sizing: border-box;
  cursor: pointer;
  p {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }
  span {
    font-size: 0.625rem;
    line-height: 110%;
    padding: 0.5rem;
  }
`;

export const TextP = styled.div`
  p {
    width: 80%;
    max-width: 55.563rem;
    margin: 0 auto;
    margin: 4.25rem auto 2rem;
    font-size: 1rem;
  }
`;

export const ButtonImgBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
`;

export const ButtonImg = styled.button`
  ${({ src }) => css`
    width: 18.938rem;
    height: 11.639rem;
    border-radius: 0.625rem;
    box-sizing: border-box;
    background-image: ${src && `url(${src})`};
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: center;
    color: var(--white-color);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0.5rem;
  `}
`;

export const ImgModal = styled.img`
  width: 100%;
`;
