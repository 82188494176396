import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  ${({ src }) => css`
    background: url(${src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    padding: 16rem 0 4rem;
    color: var(--white-color);
  `}

  .icon {
    margin-bottom: 0.875rem;
    width: 68px;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 0.875rem;
    text-align: center;
    width: 90%;
    max-width: 38rem;
  }
  p {
    margin-bottom: 1.125rem;
    font-size: 1rem;
    width: 90%;
    max-width: 23rem;
    text-align: center;
  }
`;
export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 5rem;
  gap: 1.125rem;
`;

export const NavLinkCustom = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 35px;
  border-radius: 10px;
  border: 2px solid var(--white-color);
  color: var(--white-color);
  font-size: 0.875rem;
`;
