/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Loading } from '../../components/Loading';
import { baseUrlImg, handleGetData } from '../../Services';
import { useStartupState } from '../../Providers/StartupState';
import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import { Controller, useForm } from 'react-hook-form';
import * as S from './style';

export const EventCopasul = () => {
  const [data, setData] = useState(null);
  const [optionsFilter, setOptionsFilter] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();
  const { control } = useForm();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/eventos/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          const dates = response?.data?.items[0]?.data?.eventos?.iv.map(
            (item) => {
              return item?.evento?.data;
            },
          );
          const uniqueYears = [
            ...new Set(
              dates.map((date) => ({
                value: date,
                label: format(parseISO(date), 'yyyy'),
              })),
            ),
          ];

          setOptionsFilter(uniqueYears);

          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log('Error =>', e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(data?.titulo?.iv);
    setBgHeader(data?.imagemHeader?.iv[0]);
  });

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Breadcrumbs
          content={data?.breadScrumb?.iv}
          style={{ position: 'absolute' }}
        />
        <S.Container bgOurUnits={data?.imagemBackground?.iv[0]}>
          <S.FormFilter>
            <Controller
              name="year"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <select
                  onChange={(e) => {
                    onChange(e);
                    handleYearChange(e.target.value);
                  }}
                >
                  <option value="">Escolha um ano</option>
                  {optionsFilter.map((item) => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}
            />
          </S.FormFilter>

          {data &&
            [...data?.eventos?.iv]
              .reverse()
              .filter((item) => {
                if (!selectedYear) return true;
                const year = item?.evento?.data;

                return year === selectedYear;
              })
              .map((item) => (
                <S.Card key={item?.id}>
                  <S.ContentBox>
                    <h3>
                      {item?.evento?.data && (
                        <S.SpanDate>
                          {format(new Date(item?.evento?.data), 'dd MMM', {
                            locale: pt,
                          })}
                        </S.SpanDate>
                      )}
                      {item?.evento?.titulo && item?.evento?.titulo}
                    </h3>
                    {item?.evento?.data && (
                      <p>
                        Data:
                        <span>
                          {format(
                            parseISO(item?.evento?.data),
                            "d 'de' MMMM 'de' yyyy",
                            { locale: pt },
                          )}
                        </span>
                      </p>
                    )}
                    {item?.evento?.conteudoAdicional && (
                      <p>
                        Local:
                        <span>{item?.evento?.conteudoAdicional}</span>
                      </p>
                    )}
                    {item?.evento?.link?.linkInterno && (
                      <S.LinkHere>
                        Faça sua inscrição
                        <Link to={`${item?.evento?.link?.linkInterno}`}>
                          {` ${item?.evento?.link?.tituloLink}`}.
                        </Link>
                      </S.LinkHere>
                    )}
                  </S.ContentBox>
                  {item?.evento?.imagemEvento !== null && (
                    <img
                      src={`${baseUrlImg}${item?.evento?.imagemEvento[0]}`}
                      alt="imagem do evento"
                    />
                  )}
                </S.Card>
              ))}
        </S.Container>
      </div>
    );
  }
};
