/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHeaderStates } from '../../Providers/HeaderStates';
import { format } from 'date-fns';
import { Cards } from '../../components/Cards';
import { RotatingBanner } from '../../components/RotatingBanner';
import ContactlessIcon from '@mui/icons-material/Contactless';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useStartupState } from '../../Providers/StartupState';
import { baseUrlImg, handleGetData } from '../../Services';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { ptBR } from 'date-fns/locale';
import * as S from './style';

export const Home = () => {
  const [data, setData] = useState(null);
  const [screenWidth, setScreenWidth] = useState(1);
  const [noticias, setNoticias] = useState(null);

  const { setTitle, setBgHeader } = useHeaderStates();
  const { token, setError, loading, setLoading } = useStartupState();
  const navigate = useNavigate();

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(token, 'api/content/copasul/inicio/')
        .then((response) => {
          setData(response?.data?.items[0]?.data);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setData(null);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (token) {
      handleGetData(
        token,
        `api/content/copasul/noticia/?$top=${9}&?$search=noticia`,
      )
        .then((response) => {
          setNoticias(response?.data?.items);
          if (response?.status !== 200) {
            throw new Error(response.message);
          }
        })
        .catch((e) => {
          setNoticias([]);
          console.log(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    setTitle(null);
    setBgHeader(null);
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 750) {
        return setScreenWidth(1);
      } else if (window.innerWidth >= 750 && window.innerWidth <= 1250) {
        return setScreenWidth(2);
      } else if (window.innerWidth > 1250) {
        return setScreenWidth(3);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  if (loading) {
    return <Loading />;
  } else {
    return (
      <S.Container>
        <div className="banner">
          <RotatingBanner slidesToShow={1} slidesToScroll={1}>
            {data &&
              data?.carrossel?.iv?.map((item) => (
                <S.BannerBox
                  img={`${baseUrlImg}${item?.banner?.imagemBackground[0]}`}
                  key={item.id}
                  tabIndex="0"
                >
                  <S.ContentBanner>
                    <h2 tabIndex="0">{item?.banner?.titulo}</h2>
                    <span tabIndex="0">{item?.banner?.subtitulo}</span>
                    <S.ReadMoreButton
                      tabIndex="0"
                      onClick={() => {
                        window.open(
                          `${item?.banner?.botaoLink?.linkExterno}`,
                          '_blank',
                        );
                      }}
                    >
                      Leia mais...
                    </S.ReadMoreButton>
                  </S.ContentBanner>
                  <img
                    tabIndex="0"
                    src={`${baseUrlImg}${item?.banner?.imagemSecundaria[0]}`}
                    alt={`iamgem da revista revista ${item?.banner?.subtitulo}`}
                  />
                </S.BannerBox>
              ))}
          </RotatingBanner>
        </div>
        <S.CardBox>
          {data &&
            data?.cartoes?.iv?.map((item) => (
              <Cards
                key={item?.cartao?.schemaId}
                style={{ backgroundColor: item?.cartao?.cor }}
              >
                <S.CardTextBox>
                  <h3 tabIndex="0">{item?.cartao?.titulo}</h3>
                  <p tabIndex="0">{item?.cartao?.subTitulo}</p>
                </S.CardTextBox>
                <S.CardButtonBox>
                  {item?.cartao?.link?.linkExterno && (
                    <S.ReadMoreButton
                      onClick={() => {
                        window.open(
                          `${item?.cartao?.link?.linkExterno}`,
                          '_blank',
                        );
                      }}
                      tabIndex="0"
                    >
                      {item?.cartao?.link?.tituloLink}
                    </S.ReadMoreButton>
                  )}
                  {item?.cartao?.link?.linkInterno && (
                    <S.ReadMoreButton
                      onClick={() => {
                        window.open(
                          `${item?.cartao?.link?.linkInterno}`,
                          '_self',
                        );
                      }}
                      tabIndex="0"
                    >
                      {item?.cartao?.link?.tituloLink}
                    </S.ReadMoreButton>
                  )}
                </S.CardButtonBox>
              </Cards>
            ))}
        </S.CardBox>
        <h2 tabIndex="0">Fique por dentro</h2>
        <S.NewsBannerBox>
          <RotatingBanner
            slidesToShow={screenWidth}
            slidesToScroll={screenWidth}
          >
            {noticias &&
              noticias.map((item) => (
                <S.NewsBanner key={item?.schemaId}>
                  <S.ContentNewsBanner>
                    <S.SpanDate tabIndex="0">
                      {format(new Date(item?.data?.dataNoticia?.iv), 'dd MMM', {
                        locale: ptBR,
                      })}
                    </S.SpanDate>
                    <img
                      src={`${baseUrlImg}${item?.data?.imagemNoticia?.iv[0]}`}
                      alt="imagem de capa"
                      tabIndex="0"
                    />
                    <S.ContentNewsBannerTexts>
                      <span tabIndex="0">Notícias</span>
                      <h4 tabIndex="0" title={item?.data?.titulo?.iv}>
                        {item?.data?.titulo?.iv}
                      </h4>
                      <p tabIndex="0">{item?.data?.subTitulo?.iv}</p>
                    </S.ContentNewsBannerTexts>
                    <S.ReadMoreButton
                      onClick={() => {
                        navigate(`/paginaNoticia/${item?.data?.idUrl.iv}`);
                      }}
                      tabIndex="0"
                    >
                      Leia mais...
                    </S.ReadMoreButton>
                  </S.ContentNewsBanner>
                </S.NewsBanner>
              ))}
          </RotatingBanner>
        </S.NewsBannerBox>
        <S.PodcastBox
          src={`${baseUrlImg}${data?.bannerPodcast?.iv?.imagemBackground[0]}`}
        >
          <S.PodcastBannerContent>
            <img
              src={`${baseUrlImg}${data?.bannerPodcast?.iv?.imagemAdicional[0]}`}
              alt="title"
              tabIndex="0"
            />
            <ReactMarkdown
              children={data?.bannerPodcast?.iv?.conteudoMarkdown}
              tabIndex="0"
            />
            <S.ReadMoreButton
              onClick={() => {
                window.open(
                  `${data?.bannerPodcast?.iv?.botaoLink?.linkExterno}`,
                  '_blank',
                );
              }}
              tabIndex="0"
            >
              {data?.bannerPodcast?.iv?.botaoLink?.tituloLink}
              <ContactlessIcon style={{ transform: 'rotate(-90deg)' }} />
            </S.ReadMoreButton>
          </S.PodcastBannerContent>
          <S.HandImgBox>
            <img
              tabIndex="0"
              src={`${baseUrlImg}${data?.bannerPodcast?.iv?.imagemSecundaria[0]}`}
              alt=""
            />
          </S.HandImgBox>
        </S.PodcastBox>

        <S.VideoBannerBox
          src={`${baseUrlImg}${data?.bannerAlternativo?.iv?.imagemBackground[0]}`}
        >
          <S.VideoBannerContent>
            <span tabIndex="0">{data?.bannerAlternativo?.iv?.titulo}</span>
            <h2 tabIndex="0">{data?.bannerAlternativo?.iv?.subtitulo}</h2>
            <S.ReadMoreButton
              onClick={() =>
                navigate(
                  `${data?.bannerAlternativo?.iv?.botaoLink?.linkInterno}`,
                )
              }
              tabIndex="0"
            >
              <PlayCircleOutlineIcon />
              {data?.bannerAlternativo?.iv?.botaoLink?.tituloLink}
            </S.ReadMoreButton>
          </S.VideoBannerContent>
        </S.VideoBannerBox>
      </S.Container>
    );
  }
};
